<template>
    <div class="panel-gif-browser">
        <div class="panel-gif-browser-top">
            <div class="panel-gif-browser-search">
                <input ref="input" type="text" @keyup.esc="close" v-model="term" placeholder="Search emoji.." />
            </div>
            <div class="panel-gif-browser-close" @click="close">
                <i class="material-icons">keyboard_arrow_down</i>
            </div>
        </div>
        <div class="panel-emojis" ref="container">
            <div v-for="(list, cat) in filteredEmojis" v-show="Object.keys(list).length">
                <div class="emoji-category-title" >{{ cat }}</div>
                <div class="emoji-category">
                    <div class="emoji" @click="insertEmoji(emoji)" v-for="(emoji, title) in list">{{ emoji }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import emojisList from './emoji';
    import isMobile from '../Util/IsMobile';

    export default {
        

        data() {
            return {
                term: null,
                gifs: [],
                searchTimer: null,
                searching: true,
                emojis: emojisList,
                filteredEmojis: {}
            }
        },
        
        mounted() {
            if (!isMobile.any()) {
                this.$refs.input.focus();
            }

            this.filteredEmojis = emojisList;
        },
        
        watch: {
            term() {
                this.$refs.container.scrollTop = 0;
                clearTimeout(this.searchTimer);
                this.searchTimer = setTimeout(() => {
                    this.search();
                }, 0);
            }
        },
        
        methods: {
            
            search() {
                if (this.term === null) {
                    return;
                }
                
                this.filteredEmojis = JSON.parse(JSON.stringify(emojisList));

                if (this.term.length === 0) {
                    this.term = null;
                    return;
                }
                
                for (let cat in this.emojis) {
                    for (let emoji in this.emojis[cat]) {
                        if (this.emojis[cat].hasOwnProperty(emoji) && emoji.indexOf(this.term.replace(/ /g,"_").toLowerCase()) === -1) {
                            delete this.filteredEmojis[cat][emoji];
                        }
                    }
                }
            },
            
            close() {
                this.$emit('close');
            },

            insertEmoji(emoji) {
                this.$emit('insertEmoji', emoji);
            }
        }
    }
    
</script>
