<script type="text/babel">
    
    import visitorToken from '../Util/VisitorToken';
    import phoneNumberPrefixes from './phone_number_prefixes.json';
    import isMobile from '../Util/IsMobile';

    export default {
        
        data() {
            return {
                sent: false,
                sending: false,
                phone: '',
                valid: false,
                error: false,
                focus: false
            }
        },
        
        computed: {
            intro() {
                let phone = this.$parent.widget_settings.channels.sms.phone;
                let link = '<a href="tel:'+phone+'">'+phone+'</a>';
                return this.$parent.widget_settings.translations.sms.intro.replace('{phone}', link)
            }
        },
        
        mounted() {
            this.prefillCountryPrefix();
        },
        
        methods: {
          
            send() {
                if (this.sending) {
                    return;
                }
                
                this.sending = true;
                this.error = false;

                this.$http.post('sms', {
                    vt: visitorToken.getVisitorToken(),
                    phone: this.phone,
                    locale: this.$parent.widget_settings.locale,          
                }).then(res => {
                    this.sending = false;
                    this.sent = true;
                    this.error = true;
                    this.phone = '';
                    this.prefillCountryPrefix();
                    setTimeout(this.clearState, 2000);
                }).catch(e => {
                    this.error = true;
                    this.sending = false;
                })
            },

            prefillCountryPrefix() {
                this.$http.get("https://ipinfo.io").then(res => {
                    if (res.data.country) {
                        let info = phoneNumberPrefixes[res.data.country];
                        if (info) {
                            this.phone = info.dial_code+' ';
                            if (!isMobile.any()) {
                                this.$refs.phoneInput.focus();
                            }
                        }
                    }
                });
            },
            
            clearState() {
                this.sent = false;
                this.error = false;
            }
        }
    }
</script>

<template>
    <div class="tab tab-sms">
        <div class="message-container" v-if="$parent.widget_settings.channels.sms.form == 0">
            <div class="message outbound">
                <div class="flex" >
                    <div class="">
                        <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height: 40px;" :style="{ 'background-color': $parent.widget_settings.color }">
                            <i class="material-icons" style="margin-top: 12px; font-size: 24px!important;">sms</i>
                        </span>
                    </div>
                    <div>
                        <div class="message-inner">
                            <div class="message-text">
                                <span v-html="intro"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="message-container" v-if="$parent.widget_settings.channels.sms.form == 1">
            <div class="message outbound">
                <div class="flex" >
                    <div class="">
                        <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height: 40px;" :style="{ 'background-color': $parent.widget_settings.color }">
                            <i class="material-icons" style="margin-top: 11px; font-size: 19px!important;">sms</i>
                        </span>
                    </div>
                    <div>
                        <div class="message-inner">
                            <div class="message-text">
                                <span>{{ $parent.widget_settings.translations.sms.form }}</span>
                            </div>
                        </div>
                        
                        <form @submit.prevent="send" class="sms-form">
                            <!--<input type="text" v-model="phone">-->
                            <div :class="{'input-error' : error, 'input-success' : sent }" style="width: 100%;">
                                <div class="vue-tel-input" :style="{ color: $parent.widget_settings.color, boxShadow: focus?  '0px 3px 10px ' + $parent.hexToRGB($parent.widget_settings.color):''}">
                                    <input @focus="focus=true" @blur="focus=false" type="text" ref="phoneInput" @keydown="clearState" v-model="phone" />
                                </div>
                                <div class="sms-submit">
                                    <button type="submit" class="btn" style="background: #ffffff">
                                        <span v-if="!sent">
                                            <i :style="error ? 'color: #ffffff' : 'color: #B7B7B7'" class="fa fa-angle-right" v-if="!sending && !error" />
                                            <i v-if="error" class="material-icons" style="color: #e3342f!important; font-size: 24px!important;  margin-top: 4px !important;margin-right: 6px !important;">error_outline</i>
                                            <i class="fa fa-spinner fa-spin" v-if="sending" />
                                        </span>
                                        <span v-else>
                                            <i class="fa fa-check" style="font-size: 12px; color: #ffffff" v-if="sent"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
