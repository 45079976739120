<script type="text/babel">

    export default {

        data()
        {
            return {
                visible: false
            }
        },

        props: ['color', 'message', 'position'],

        mounted()
        {
            this.visible = this.show;
        },

        computed: {
            showOnlineStatus() {
                return typeof(parent.Trengo.launcherOnlineStatus) === 'undefined' || parent.Trengo.launcherOnlineStatus !== false;
            },
        },

        methods:
        {
            openPanel()
            {
                window.Trengo.eventBus.$emit('widget.open');
            }
        }
    }
</script>

<template>
    <div class="message-container" @click="openPanel"  :style="{background: this.color }" :class="{'position-left':this.position == 'LEFT'}">
        <span class="online" v-if="showOnlineStatus"></span> {{ message }}
    </div>
</template>
