<script type="text/babel">

    export default {

        data()
        {
            return {
                cdn_url: process.env.CDN_URL,
                icon: null,
                open: false
            }
        },

        // computed: {
        //     icon() {
        //         return this.isOpen ? 'zmdi zmdi-close' : 'zmdi zmdi-comment-text';
        //     }
        // },

        props: ['position', 'color', 'channels', 'chatbot', 'icon_url', 'isOpen', 'flowbot'],

        mounted()
        {
            this.open = this.isOpen;

            if (this.open) {
                this.resetCloseAnimation();
            }

            if (!this.chatbot && !this.channels.chat && Object.keys(this.channels).length === 1) {
                let first = Object.keys(this.channels)[0];
                if (first === 'twitter') {
                    this.icon = 'fa fa-twitter';
                }
                if (first === 'facebook') {
                    this.icon = 'fa fa-facebook';
                }
                if (first === 'telegram') {
                    this.icon = 'fa fa-paper-plane';
                }
                if (first === 'whatsapp') {
                    this.icon = 'fa fa-whatsapp';
                }
                if (first === 'email') {
                    this.icon = 'fa fa-envelope-o';
                }
            }

            Trengo.eventBus.$on('widget.open', () => {
                this.open = true;
                this.resetCloseAnimation();
            });

            Trengo.eventBus.$on('widget.close', () => {
                this.open = false;
            })
        },

        methods: {
            resetCloseAnimation() {
                if (!this.$refs.launcher) {
                    return;
                }

                let spans = this.$refs.launcher.querySelectorAll('span');
                for (var i = 0; i < spans.length; i++) {
                    spans[i].style.animationDelay = '0.1s';
                }
            }
        }
    }
</script>

<template>
    <div class="launcher-container" :class="{'left': position === 'LEFT' && !icon_url && !flowbot && !chatbot && !flowbot.active}" @click="$emit('toggle')"  :style="{background: this.color, borderBottomRightRadius: chatbot || icon_url || flowbot || flowbot.active ? '22px' : '0px'}">

        <!--<transition name="fade" mode="out-in">-->
            <span v-if="icon_url && !open" key="icon_url">
                <img :src="icon_url" 
                     alt="Trengo Launcher icon" 
                     style="width: 100%; height: 100%; object-fit: cover;" />
            </span>

            <span style="" v-else-if="chatbot && !open" key="chatbot">
                <img :src="cdn_url+'assets/chatbot.svg'" 
                     alt="" 
                     style="width: 60px;" />
            </span>

            <i v-else-if="icon && !open" :class="icon" key="icon"></i>
        <div v-else class="launcher-stripes" :class="{'left': position === 'LEFT'}">
            <span class="trengo_launcher " ref="launcher" :class="{'state--open': open }" key="brand">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </div>
        <!--</transition>-->

    </div>
</template>
