<template>
    <div class="help-center-article" ref="articleWrap">
        <div class="article">
            <h1>{{$parent.getTranslation(article).title}}</h1>
            <div class="content" v-html="$parent.getTranslation(article).content"></div>
            <vote :article="article.token" :widget_settings="widget_settings" :title="widget_settings.translations.help_center.vote_title || 'Was this article helpful?'"></vote>
        </div>
    </div>
</template>

<script>
    import Vote from './Vote';

    export default {
        name: "Article",

        props: ['article', 'widget_settings'],

        components: {
            Vote
        },

        watch: {
            article(val) {
                // when article changed: processLinks again, after DOM update
                this.$nextTick(this.processLinks);
                this.setTitle();

                this.scrollUp();

            }
        },

        mounted() {
            // styling links
            const style = document.createElement("style");
            style.append(`.help-center-article a {color: ${this.widget_settings.color};}`);
            this.$refs.articleWrap.appendChild(style);

            this.processLinks();
            this.setTitle();
            this.scrollUp();
        },

        methods: {
            processLinks() {
                // get all links
                this.$refs.articleWrap.querySelectorAll('.content a').forEach(link => {
                    if(link.href.startsWith(this.widget_settings.channels.help_center.url)) {
                        // if helpCenter link, load it inside the widget
                        const hcLink = link.href.substr(this.widget_settings.channels.help_center.url.length + 4); // assume 4 chars for locale + 2 slash. todo regex and also support without locale code nor slug
                        link.addEventListener('click', (e) => {e.preventDefault(); this.$parent.loadPage(hcLink);})
                    } else if(!link.href.startsWith(window.location.origin)) {
                        // or external link, open in new tab and add rel noopener
                        link.rel = 'noopener noreferrer';
                        link.target = '_blank'
                    }/* else {
                        // link on same domain, do nothing.
                    }*/
                });
            },
            setTitle() {
                this.$parent.setTitle(this.$parent.getTranslation(this.article).title, this.$parent.widget_settings.translations.help_center.read_online || 'Read online', 'articles/'+this.$parent.getTranslation(this.article).slug);
            },
            scrollUp() {
                if(this.$parent.$parent.$refs.scrollContainer.scrollTo != undefined) {
                    this.$parent.$parent.$refs.scrollContainer.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                } else {
                    // IE && safari
                    this.$parent.$parent.$refs.scrollContainer.scrollTop = 0;
                }
            }
        }
    }
</script>
