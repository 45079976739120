import xor from './Xor';
import isMobile from './IsMobile';
const { DateTime } = require("luxon");

var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (isIE11) {
    require('core-js')
}

// https://developer.mozilla.org/nl/docs/Web/JavaScript/Guide/Reguliere_Expressies
function escapeRegExp(string = '') {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const visitorSalt = 'fgdxhjukiLIUDSKYIu6(*$HI*349403H(#)hoskjdfhs';
//const payloadSalt = 'YeKCH8LYFK3rWrWJzauYU6kM';

const automationObject = {
    visitorKey: '',
    payloadKey: '',

    storageData: null,
    urlParams: new URLSearchParams(document.location.search.substring(1)),

    previousLastSeen: null,

    // main method
    init(automations) {
        automationObject.visitorKey = window.localStorage.getItem('Trengo_VisitorToken') + visitorSalt;
        //automationObject.payloadKey = window.Trengo.key + payloadSalt;

        automationObject.setStorageData();

        for(let automationIndex in (automations||[])) {
            let automation = automations[automationIndex];

            // TODO Feature: Triggers. for now only seconds_on_page, as a condition.

            let secondsOnPageCondition = {value: 0.4};

            /*try {
                automation.payload = JSON.parse(xor.decode(automation.payload, automationObject.payloadKey));
            } catch (e) {
                console.log('invalid automation json');
            }*/

            if(automation.payload && automation.payload.conditions) {
                secondsOnPageCondition = automation.payload.conditions.find(cond => cond.type === 'seconds_on_page') || {value: 0.4};
            }

            // minimal timeout is 1 second, otherwise the greeting could be faster than the widget itself.
            if(secondsOnPageCondition.value < 0.4) {
                secondsOnPageCondition.value = 0.4;
            }

            setTimeout(() => automationObject.runAutomation(automation), (secondsOnPageCondition.value || 0.4) * 1000);
        }
    },

    runAutomation(automation) {
        if(!automation.payload) {
            return;
        }

        // each condition should return true. if we have subconditions then one of the subconditions have to return true.
        let andChain = true;

        for(let conditionIndex in automation.payload.conditions) {
            let condition = automation.payload.conditions[conditionIndex];

            if(condition.subconditions && condition.subconditions.length) {
                andChain = andChain && condition.subconditions.some(subcondition => {if(subcondition.type in automationObject.conditions) return automationObject.conditions[subcondition.type](subcondition.operator, subcondition.value)});
            } else {
                if(condition.type in automationObject.conditions) {
                    andChain = andChain && automationObject.conditions[condition.type](condition.operator, condition.value);
                } else if(window.Trengo.automation && condition.type in window.Trengo.automation) {
                    // custom fields TODO rl: make checking conditions recursive to support custom fields in subconditions
                    automationObject.switchOperators(window.Trengo.automation[condition.type], condition.operator, condition.value);
                }
            }

            if(!andChain) {
                return;
            }
        }

        // all conditions are true. continue running the actions
        for(let actionIndex in automation.payload.actions) {
            let action = automation.payload.actions[actionIndex];

            if(!(action.type in automationObject.actions)) {
                continue;
            }

            automationObject.actions[action.type](Object.assign({}, {automationId: automation.id}, action.payload));
        }
    },

    loadStorageData() {
        let data = window.localStorage.getItem('Trengo_Visitor');
        if(data) {
            try {
                automationObject.storageData = JSON.parse(xor.decode(data, automationObject.visitorKey));

                if(!automationObject.storageData) {
                    throw new Error('Corrupted data');
                }
            } catch(e) {
                // reset data
                automationObject.storageData = {totalVisits: 0, totalPageViews: 0, lastSeen: null, lastContact: null};
            }
        } else {
            // create data
            automationObject.storageData = {totalVisits: 0, totalPageViews: 0, lastSeen: null, lastContact: null};
        }
    },
    setStorageData() {
        automationObject.loadStorageData();

        // total page views
        automationObject.storageData.totalPageViews++;

        // total sessions
        if(!window.sessionStorage.getItem('Trengo_Session')) {
            window.sessionStorage.setItem('Trengo_Session', '1');
            automationObject.storageData.totalVisits++;
        }

        // last seen
        automationObject.previousLastSeen = automationObject.storageData.lastSeen;
        automationObject.storageData.lastSeen = (new Date()).getTime();

        // last contact is set on message sent
        window.Trengo.eventBus.$on('update_last_contact', e => {
            automationObject.storageData.lastContact = (new Date()).getTime();
            automationObject.updateLocalStorage();
        });

        automationObject.updateLocalStorage();
    },
    updateLocalStorage() {
        let data = xor.encode(JSON.stringify(automationObject.storageData), automationObject.visitorKey);
        window.localStorage.setItem('Trengo_Visitor', data);
    },

    switchOperators(a, operator, b, allowedOperators = ['=', '!=', '>', '<', 'starts_with', 'ends_with', 'contains', 'is_after', 'is_before'], wildcard = false) {
        if(allowedOperators.indexOf(operator) === -1) {
            return false;
        }

        if(wildcard) {
            switch(operator) {
                case 'contains':
                    return (new RegExp(escapeRegExp(b).replace(/\\\*/gi, '.*', true))).test(a);
                case '=':
                    return (new RegExp('^'+escapeRegExp(b).replace(/\\\*/gi, '.*')+'$')).test(a);
                case '!=':
                    return !(new RegExp('^'+escapeRegExp(b).replace(/\\\*/gi, '.*')+'$')).test(a);
                case 'starts_with':
                    return (new RegExp('^'+escapeRegExp(b).replace(/\\\*/gi, '.*'))).test(a);
                case 'ends_with':
                    return (new RegExp(escapeRegExp(b).replace(/\\\*/gi, '.*')+'$')).test(a);
                default:
                    return false;
            }
        }

        switch(operator) {
            case '=':
                return a === b;
            case '>':
                return a > b;
            case '<':
                return a < b;
            case '!=':
                return a !== b;
            case 'starts_with':
                return a.startsWith(b);
            case 'ends_with':
                return a.endsWith(b);
            case 'contains':
                return a.includes(b);
            case 'is_after':
                 return DateTime.now() > DateTime.fromMillis(b);
            case 'is_before':
                return DateTime.now() < DateTime.fromMillis(b);
            default:
                return false;
        }
    },

    conditions: {
        last_seen(operator, value) {
            const lastSeen = automationObject.previousLastSeen;

            // never seen before, not more than x days, not less than x days
            if(lastSeen === null) {
                return false;
            }

            let daysAgo = 1000*60*60*24*parseInt(value);
            let now = (new Date).getTime();

            return automationObject.switchOperators(now - lastSeen, operator, daysAgo, ['<', '>']);
        },
        last_contact(operator, value) {
            const lastContact = automationObject.storageData.lastContact;

            if(lastContact === null) {
                return false;
            }

            let daysAgo = 1000*60*60*24*parseInt(value);
            let now = (new Date).getTime();

            return automationObject.switchOperators(now - lastContact, operator, daysAgo, ['<', '>']);
        },
        total_visits(operator, value) {
            const totalVisits = automationObject.storageData.totalVisits || 0;

            return automationObject.switchOperators(parseInt(totalVisits), operator, parseInt(value), ['<', '>', '=']);
        },
        total_page_views(operator, value) {
            const totalViews = automationObject.storageData.totalPageViews || 0;

            return automationObject.switchOperators(parseInt(totalViews), operator, parseInt(value), ['<', '>', '=']);
        },
        browser_lang(operator, value) {
            const lang = (navigator.language || navigator.userLanguage);

            return lang.startsWith(value);
        },
        utm_source(operator, value) {
            const utm = automationObject.urlParams.get('utm_source') || '';

            return automationObject.switchOperators(utm, operator, value, ['contains', 'starts_with', 'ends_with', '=']);
        },
        utm_campaign(operator, value) {
            const utm = automationObject.urlParams.get('utm_campaign') || '';

            return automationObject.switchOperators(utm, operator, value, ['contains', 'starts_with', 'ends_with', '=']);
        },
        utm_medium(operator, value) {
            const utm = automationObject.urlParams.get('utm_medium') || '';

            return automationObject.switchOperators(utm, operator, value, ['contains', 'starts_with', 'ends_with', '=']);
        },
        utm_term(operator, value) {
            const utm = automationObject.urlParams.get('utm_term') || '';

            return automationObject.switchOperators(utm, operator, value, ['contains', 'starts_with', 'ends_with', '=']);
        },
        utm_content(operator, value) {
            const utm = automationObject.urlParams.get('utm_content') || '';

            return automationObject.switchOperators(utm, operator, value, ['contains', 'starts_with', 'ends_with', '=']);
        },
        referrer_url(operator, value) {
            const url = document.referrer || '';

            return automationObject.switchOperators(url, operator, value, ['contains', 'starts_with', 'ends_with', '='], true);
        },
        mobile_device(operator, value) {
            return isMobile.any() === value;
        },
        returning_visitor(operator, value) {
            return automationObject.conditions.total_visits('>', 1) === value;
        },
        page_url(operator, value) {
            const url = window.parent.location.href || '';

            if(!value|| !url) {
                return true; // ignore condition if value not set
            }

            return automationObject.switchOperators(url, operator, value, ['contains', 'starts_with', 'ends_with', '='], true);
        },
        start_date(operator, value) {
            if(!value) {
                return true; // ignore condition if value not set;
            }
            return automationObject.switchOperators(undefined, 'is_after', value);
        },
        end_date(operator, value) {
            if(!value) {
                return true; // ignore condition if value not set;
            }
            return automationObject.switchOperators(undefined, 'is_before', value);
        },
    },

    actions: {
        greeting(payload) {
            Trengo.eventBus.$emit('greeting.show', payload);
        },
        setFlowbot(payload) {
            Trengo.eventBus.$emit('flowbot.set', payload);
        },
        openHelpCenter(payload) {
            Trengo.eventBus.$emit('helpCenter.open', payload);
        }
    }
};

export default automationObject;
