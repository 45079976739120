<script type="text/babel">

    import isMobile from '../Util/IsMobile';
    import visitorToken from '../Util/VisitorToken';

    export default {

        props: ['message', 'ticket', 'color'],

        data()
        {
            return {
                email: this.ticket.contact.email,
                loading: false,
                removable: false,
                focus: false
            }
        },

        mounted()
        {
            if (this.email == null) {
                setTimeout(() => {
                    if (!isMobile.any()) {
                        // this.$refs.emailInput.focus();
                    }
                }, 500);
            }

        },

        methods: {

            setEmail(email)
            {
                this.email = email
                this.onSubmitted();
            },

            onButtonClicked()
            {
                if (this.removable) {
                    this.onCleared();
                }
                else {
                    this.onSubmitted();
                }
            },

            onCleared()
            {
                this.$http.post('ticket/email', {
                    email: null,
                    vt: visitorToken.getVisitorToken(),
                    guid: this.ticket.guid,
                    locale: this.$parent.widget_settings.locale,
                });
                this.email = null;
                this.removable = false;
                this.$emit('email-cleared');
                this.$nextTick(() => {
                    if (!isMobile.any()) {
                        // this.$refs.emailInput.focus();
                    }
                }, 200);
            },

            onSubmitted()
            {
                if (this.email == null || this.email == '' || !this.validateEmail(this.email)) {
                    return;
                }

                this.loading = true;

                this.$http.post('ticket/email', {
                    email: this.email,
                    vt: visitorToken.getVisitorToken(),
                    guid: this.ticket.guid,
                    locale: this.$parent.widget_settings.locale,

                }).then(res => {
                    this.loading = false;
                    Trengo.eventBus.$emit('widget.email.set');
                    this.$emit('email-set', {
                        email: this.email
                    });
                }).catch((e) => {
                    alert('Something went wrong. Please try again.');
                    this.loading = false;
                });
            },

            onMouseOver()
            {
                if (this.ticket.contact.email == null) {
                    return;
                }

                this.removable = true
            },

            onMouseOut()
            {
                if (this.ticket.contact.email == null) {
                    return;
                }

                this.removable = false
            }
        }

    }
</script>

<template>
    <div class="message  ">
        <div class="flex">
            <div class="">
                <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height:40px;" :style="{ 'background-color': color }">
                    <i class="material-icons" style="margin-top: 10px; font-size: 19px">chat_bubble</i>
                </span>
            </div>
            <div class="message-inner">
                <div class="message-text">
                    <span v-html="message.message"></span>
                </div>
            </div>
        </div>
        <form ref="captureForm" class="chat-capture-form" @submit.prevent="onButtonClicked()">
            <div class="input-group" :style="{boxShadow: focus?  '0px 3px 10px ' + $parent.hexToRGB($parent.widget_settings.color) : '' }">
                <input type="email" v-if="!ticket.contact.email" @focus="focus =true" @blur="focus=false" ref="emailInput" :disabled="ticket.contact.email != null" placeholder="info@example.com" v-model="email" />
                <div class="filled" v-else>{{ticket.contact.email}}</div>
                <div class="chat-capture-submit">
                    <button type="submit" class="btn" @mouseover="onMouseOver" @mouseout="onMouseOut">
                        <span style="color: #B7B7B7; font-size: 25px" v-if="ticket.contact.email == null">
                            <i :style="{color : email ? color : '#B7B7B7'}" class="fa fa-angle-right" v-if="!loading" />
                            <i class="fa fa-spinner fa-spin" v-if="loading" />
                        </span>
                        <span style="color: #B7B7B7; font-size: 25px" v-else>
                            <i class="fa fa-check" style="font-size: 27px;" v-if="ticket.contact.email == null"></i>
                            <i class="fa fa-times-circle" style="font-size: 18px;" v-else></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>
