<template>
    <div class="panel-gif-browser" style="padding: 15px 0px 24px 15px; font-size: 15px; height: 277px">
        <div class="panel-gif-browser-top" style="margin-bottom: 0px">
            <h4 class="truncate title pb-2" style="color: #9E9E9E; width: 93%; line-height: 50px">{{$parent.widget_settings.translations.help_center.search_results_for || 'Search results for'}} “<span :style="{color: $parent.widget_settings.color}">{{searchString}}</span>”</h4>
            <div class="panel-gif-browser-close" style="padding-right: 7px" @click="close">
                <i class="material-icons search-arrow">keyboard_arrow_down</i>
            </div>
        </div>
        <div class="panel-gifs" style="overflow-x: hidden!important; display: block" ref="container">
            <ul>
                <li v-for="result in searchResults.articles" :style="'color: '+($parent.widget_settings.color || '#999')" class="article block truncate py-1 pr-6">
                    <i class="material-icons">library_books</i>
                    <a style="position: relative; bottom: 7px; color: #000000; text-decoration: none" :href="result.url" @click.prevent="$parent.loadPage('articles/'+result.slug)">{{result.title}}</a>
                </li>
                <li v-if="Object.keys(searchResults.articles).length === 0" :style="'color: '+($parent.widget_settings.color || '#999')" class="article block truncate py-1">
                    <i class="material-icons">find_replace</i>
                    <span style="position: relative; bottom: 7px; color: #000000">{{$parent.widget_settings.translations.help_center.no_results_found || 'No results found.'}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchResults",

        props: ['searchResults'],

        computed: {
            searchString() {
                return decodeURIComponent(this.searchResults.path.split('=')[1] || '');
            }
        },

        methods: {
            close() {
                this.$emit('close');
            },
        }
    }
</script>