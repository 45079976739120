<template>
    <div style="text-align: center;display: block; padding: 12px;  ">
        <span class="message-button" style="margin: 12px;" :style="{color: color, boxShadow: '0px 3px 12px ' + $parent.hexToRGB(color) }" @click="$emit('selected', button)" v-for="button in meta.buttons">{{ button.text }}</span>
    </div>
</template>

<script>
    export default {
        props: ['meta', 'color']
    }
</script>

