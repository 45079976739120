<script type="text/babel">

    import isMobile from '../Util/IsMobile';

    export default
    {
        mounted()
        {
            if (!isMobile.any()) {
                this.$parent.$refs.link.target = '_blank';
            }
        },

        computed: {

            text()
            {
                if (!this.shouldDisplayApiLink()) {
                    let phone = this.$parent.widget_settings.channels.whatsapp.identifier;
                    return '+'+phone.substr(0,2)+' '+phone.substr(2, 1)+' '+phone.substr(3, 4)+' '+phone.substr(7, 4);
                }

                return this.$parent.widget_settings.translations.whatsapp.button;
            },

            link()
            {
                const phoneNumber = this.$parent.widget_settings.channels.whatsapp.identifier.replace(/\D/g, '');
                if (!this.shouldDisplayApiLink()) {
                    return 'tel:'+this.$parent.widget_settings.channels.whatsapp.identifier;
                }
                return 'https://api.whatsapp.com/send?phone='+phoneNumber
            }
        },

        methods:
        {
            shouldDisplayApiLink()
            {
                return true;
                // The WhatsApp API link only works on mobiles or Google Chrome Desktop
                var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                return isMobile.any();
            }
        }
    }
</script>

<template>


    <div class="tab tab-whatsapp">
        <div class="message-container">
            <div class="message outbound">
                <div class="flex">
                    <div class="    ">
                        <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height:40px;" :style="{ 'background-color': $parent.widget_settings.color }">
                            <span>
                                <i class="fa fa-whatsapp"></i>
                            </span>
                        </span>
                    </div>
                    <div class="message-inner">
                        <div class="message-text">
                            <span>{{ $parent.widget_settings.translations.whatsapp.intro }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
