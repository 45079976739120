<template>
    <div class="help-center-index">
        <ul class="list-reset mt-2">
            <li v-for="category in categories" @click.prevent="$parent.loadPage($parent.getTranslation(category).slug)" class="category py-3 flex">
                <div class="pr-4">
                    <div class="help-center-icon" :style="'background: '+($parent.widget_settings.color || '#999')">
                        <i style="font-size: 24px!important;" class="material-icons text-3xl">{{category.icon}}</i>
                    </div>
                </div>
                <div style="min-width:0;overflow:hidden">
                    <div class="title truncate " style="color: #000000; font-weight: 600;">
                        {{$parent.getTranslation(category).title}}
                    </div>
                    <div class="description truncate" style="color: #000000;">
                        {{$parent.getTranslation(category).description}}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Index",

        props: {
            categories: Array,
            /*blocks: Array*/
        },

        computed: {
            totalArticles() {
                let total = 0;
                this.categories.forEach(cat => {
                    total += cat.articles.length;
                });
                return total;
            }
        },

        mounted() {
            this.$parent.setTitle(this.$parent.widget_settings.translations.help_center.title || 'Help Center', this.totalArticles + ' ' + (this.$parent.widget_settings.translations.help_center.articles || 'articles'), '/'); // TODO translation pluralization?
        }
    }
</script>
