<template>
    <div  v-if="status !== null" style="text-align: center;display: block;width: 100%;">
        <template v-if="status === 'REQUEST_SENT'">
            <div class="message-container">
                <div>
                    <div class="message  ">
                        <div class="flex">
                            <div class="">
                            <span class="panel-team-avatar" style="float: left; line-height: 40px; width: 40px; height: 40px; background-color: rgb(91, 177, 48);"><i class="material-icons" style="margin-top: 10px;">chat</i></span></div>
                            <div class="message-inner " >
                                <div class="message-text text-left select-none  " style="white-space: normal;">
                                    <div class="mt-1">{{ widget_settings.translations.chat.screen_share_invite_request.replace(':agent', ticket.agent.first_name)  }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button @click="accept" class="message-button"  :style="{color: color, boxShadow: '0px 3px 12px ' + $parent.hexToRGB(color) }">
                    <span>{{ $parent.widget_settings.translations.chat.screen_share_invite_accept}}</span>
                </button>
                <button @click="decline" class="message-button"  :style="{color: color, boxShadow: '0px 3px 12px ' + $parent.hexToRGB(color) }">
                    <span>{{ $parent.widget_settings.translations.chat.screen_share_invite_decline }}</span>
                </button>
            </div>
        </template>

        <template v-if="status === 'ACCEPTED'">
            <div class="message-container">
                <div>
                    <div class="message  ">
                        <div class="flex">
                            <div class="">
                                <span class="panel-team-avatar" style="float: left; line-height: 40px; width: 40px; height: 40px; background-color: rgb(91, 177, 48);"><i class="material-icons" style="margin-top: 10px;">chat</i></span></div>
                            <div class="message-inner ">
                                <div class="message-text text-left select-none  " style="white-space: normal;">
                                    <div class="">{{ widget_settings.translations.chat.screen_share_invite_starting  }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="status === 'RUNNING'">
            <div class="message-container">
                <div>
                    <div class="message  ">
                        <div class="flex">
                            <div class="">
                                <span class="panel-team-avatar" style="float: left; line-height: 40px; width: 40px; height: 40px; background-color: rgb(91, 177, 48);"><i class="material-icons" style="margin-top: 10px;">chat</i></span></div>
                            <div class="message-inner ">
                                <div class="message-text text-left select-none  " style="white-space: normal;">
                                    <div class="">{{ widget_settings.translations.chat.screen_share_invite_active.replace(':agent', ticket.agent.first_name)  }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button @click="stop" class="message-button"  :style="{color: color, boxShadow: '0px 3px 12px ' + $parent.hexToRGB(color) }">
                    <span>{{ widget_settings.translations.chat.screen_share_invite_stop}}</span>
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: ['message', 'color', 'presenceChannel', 'ticket', 'widget_settings'],

        data() {
            return {
                ready: false,
                status: null,
                session: null
            }
        },

        mounted() {
            this.$nextTick(this.bindPusher());
            window.Trengo.eventBus.$on('surfly.ready', () => {
                setTimeout(this.onReady, 250); // small timeout because weird pusher (says it's subscribed but its not..)
            });
        },

        destroyed() {
            parent.Trengo.eventBus.$off('widget.cobrowse.ready')
        },

        methods: {

            bindPusher() {
                this.presenceChannel.bind('pusher:member_added', (member) => {
                    if (this.ready) {
                        setTimeout(() => {
                            this.presenceChannel.trigger('client-surfly.ready', {
                                link: this.session !== null ? this.session.leaderLink : null
                            });
                        }, 2000);
                    }
                });
                this.presenceChannel.bind('client-surfly', (s) => {
                    if (s.status === 'READY') {
                        this.stop();
                    }
                    this.status = s.status;
                    this.$parent.scrollToBottom();
                });
            },

            onReady() {
                this.ready = true;
                this.presenceChannel.trigger('client-surfly.ready', {});

                parent.Surfly.listSessions().forEach((session) => {
                    this.session = session;
                    this.status = 'RUNNING';
                    this.presenceChannel.trigger('client-surfly', {
                        status: 'RUNNING',
                        link: session.followerLink
                    });
                });
            },

            accept() {
                this.status = 'ACCEPTED';
                this.presenceChannel.trigger('client-surfly', {
                    status: 'ACCEPTED'
                });

                this.session = parent.Surfly.session().on('session_started', (session) => {
                    this.status = 'RUNNING';
                    this.presenceChannel.trigger('client-surfly', {
                        status: 'RUNNING',
                        link: this.session.followerLink
                    });
                }).on('session_ended', (session) => {
                    this.stop();
                }).startLeader(null, {
                    name: 'Visitor'
                });
            },

            decline() {
                this.status = 'DECLINED';
                this.presenceChannel.trigger('client-surfly', {
                    status: 'DECLINED'
                });
            },

            stop() {
                this.status = null;
                this.presenceChannel.trigger('client-surfly', {
                    status: 'CANCELLED'
                });
                if (this.session) {
                    this.session.end();
                }
            }
        }
    }
</script>
