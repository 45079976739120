<script type="text/babel">
    import Index from './HelpCenter/Index';
    import Category from './HelpCenter/Category';
    import Article from './HelpCenter/Article';
    import SearchResults from './HelpCenter/SearchResults';

    export default {

        props: ['widget_settings', 'help_center_article', 'automation'],

        data()
        {
            return {
                article: null,
                title: null,
                blocks: null,
                categories: null,
                category: null,
                currentPage: '',
                history: [],
                searchString: '',
                searchResults: null,
                helpCenter: null,
            }
        },

        components: {
            Index,
            Category,
            Article,
            SearchResults
        },

        watch: {
            currentPage(val) {
                this.$parent.panelHead.helpCenterPage = val;
            }
        },

        beforeMount() {
            if (this.help_center_article.articleId && this.automation) {
                this.loadPage('articles/' + this.help_center_article.articleId + '-', false)
                Trengo.eventBus.$on('helpCenter.goBack', this.goBack);
                Trengo.eventBus.$on('helpCenter.search', this.search);
                this.$parent.automation = false
            } else {
                this.loadPage('', false);
                Trengo.eventBus.$on('helpCenter.goBack', this.goBack);
                Trengo.eventBus.$on('helpCenter.search', this.search);
            }
        },

        beforeDestroy() {
            this.$parent.panelHead.helpCenterPage = '';
            this.setTitle();
            Trengo.eventBus.$off('helpCenter.goBack', this.goBack);
            Trengo.eventBus.$off('helpCenter.search', this.search);
        },

        methods:  {
            getTranslation(item) {
                return (
                    // try to find the browser lang
                    item.translations.find(trans => {
                        return trans.locale_code === (this.widget_settings.browser_lang);
                    })
                    ||
                    // or get the widget default language
                    item.translations.find(trans => {
                        return trans.locale_code === this.widget_settings.default_lang;
                    })
                    ||
                    // or get the helpCenter default language (should work)
                    item.translations.find(trans => {
                        return trans.locale_code === this.helpCenter.default_language;
                    })
                    ||
                    // try English as fallback
                    item.translations.find(trans => {
                        return trans.locale_code === 'en';
                    })
                    ||
                    {}
                );
            },

            /**
             * with fallback
             */
            getHelpCenterLocale() {
                // try browser language
                if(this.widget_settings.channels.help_center && this.widget_settings.channels.help_center.languages.indexOf(this.widget_settings.browser_lang) > -1) {
                    return this.widget_settings.browser_lang;
                }

                // try widget default language
                if(this.widget_settings.channels.help_center && this.widget_settings.channels.help_center.languages.indexOf(this.widget_settings.default_lang) > -1) {
                    return this.widget_settings.default_lang;
                }

                // try helpCenter default lang
                if(this.widget_settings.channels.help_center && this.widget_settings.channels.help_center.default_language) {
                    return this.widget_settings.channels.help_center.default_language;
                }

                // English fallback
                return 'en';
            },

            setTitle(title = '', subTitle = '', path = '') {
                this.$parent.panelTitle.title = title;
                this.$parent.panelTitle.subTitle = subTitle;
                this.$parent.panelTitle.link = path ? (this.widget_settings.channels.help_center.url+'/'+this.getHelpCenterLocale()+'/'+path) : '';
            },

            loadPage(path = '', addToHistory = true) {
                this.$http.get(this.widget_settings.channels.help_center.url+'/'+this.getHelpCenterLocale()+'/'+path).then(r => {
                    if(addToHistory && this.$parent.helpCenterHistory[this.$parent.helpCenterHistory.length -1] !== this.currentPage) {
                        this.$parent.helpCenterHistory.push(this.currentPage);
                    }
                    this.currentPage = path;
                    this.article = r.data.article || null;
                    this.categories = r.data.categories || null;
                    this.category = r.data.category || null;
                    this.blocks = r.data.blocks || null;
                    this.searchResults = r.data.searchResults ? {path: path, articles: r.data.searchResults || []} : null;

                    // don't unset help_center
                    if(r.data.help_center) {
                        this.helpCenter = r.data.help_center;
                    }
                }).catch(e => {
                    console.log('error loading page', e);
                });
            },

            goBack() {
                this.loadPage(this.$parent.helpCenterHistory.pop(), false);
            },

            search(searchString) {
                this.$http.get(this.widget_settings.channels.help_center.url+'/'+this.getHelpCenterLocale()+'/'+'search?term='+ encodeURIComponent(searchString)).then(r => {
                    this.searchResults = r.data.searchResults ? {path: 'search?term='+ encodeURIComponent(searchString), articles: r.data.searchResults || []} : null;
                }).catch(e => {
                    console.log('error loading page', e);
                });
            }
        }
    }
</script>

<template>
    <div style="overflow-x: hidden;">
        <transition name="slide-fade" mode="out-in">
            <Index v-if="categories" :categories="categories"></Index>
            <Category v-else-if="category" :category="category"></Category>
            <Article v-else-if="article" :article="article" :widget_settings="widget_settings"></Article>
        </transition>
        <transition name="slide-fade-up">
            <SearchResults v-if="searchResults" :search-results="searchResults" @close="searchResults = false"></SearchResults>
        </transition>
    </div>
</template>
