export default {
    sendEventToGa(setting, eventName) {
        if (setting && eventName !== 'chat online' && eventName !== 'chat offline') {
            if (window.parent.gtag) {
                window.parent.gtag('event', eventName, {
                    'event_category': 'trengo widget'
                });
            } else if (window.parent.ga) {
                if (window.parent.ga.getAll()) {
                    window.parent.ga.getAll()[0].send({
                        hitType: 'event',
                        eventCategory: 'trengo widget',
                        eventAction: eventName,
                    });
                } else {
                    window.parent.ga('send', {
                        hitType: 'event',
                        eventCategory: 'trengo widget',
                        eventAction: eventName,
                    })
                }
            }
        }
    },
}
