<script type="text/babel">
    import '../Util/IsMobile'
    import IsMobile from "../Util/IsMobile";

    export default {

        data()
        {
            return {
            };
        },

        props: {
            'from': Object, 'message': String, 'widget_settings': Object, 'color': String, 'position': String
        },

        mounted()
        {
        },

        methods:
        {
            isMobile() {return IsMobile.any()},

            nl2br(str = '') {
                return str.replace(/\n/g, "<br />");
            },

            htmlEntities(str = '') {
                return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
            },
        },

        computed: {
            randomUserFromTeam() {
                if(this.from.team) {
                    return this.from.team[Math.floor(Math.random() * this.from.team.length)];
                }
            },

            getMessageHtml() {
                return this.nl2br(this.htmlEntities(this.message || ''))
            }
        },
    }
</script>

<template>
    <div class="greeter">
        <div class="greeter-box" :class="{'left': position === 'LEFT'}">
            <div @click="$emit('greetingClicked')" style="position: absolute; width: 100%; margin-top: -20px; flex-shrink: 0;">
                <div style="display: flex; justify-content: center">
                    <template v-if="from.fromType === 'random' && randomUserFromTeam">
                        <div v-for="avatar in randomUserFromTeam.avatars" v-if="avatar" class="circle avatar mr-1" style="display: inline-block; width: 40px; height: 40px; line-height: 1;">
                            <img v-if="avatar.image" :src="avatar.image" alt="avatar" style="width: 40px; height: 40px;">
                            <span v-else class="text-white circle" style="line-height: 42px; width: 40px; border-radius: 14px; height:  40px; text-align: center; display: inline-block;" :style="{'background-color': avatar.color}">{{avatar.abbr.substring(0, 1)}}</span>
                        </div>
                    </template>
                    <template v-else-if="from.fromType !== 'random'">
                        <div v-for="avatar in from.avatars" v-if="avatar && from.avatars.length !== 2" class="circle avatar mr-1" style="display: inline-block; width: 40px; height: 40px; line-height: 1;">
                            <img v-if="avatar.image" :src="avatar.image" style="width: 40px; height: 40px;">
                            <span v-else class="text-white circle" style="line-height: 42px; width: 40px;height: 40px; text-align: center; display: inline-block;" :style="{'background-color': avatar.color}">{{avatar.abbr.substring(0, 1)}}</span>
                        </div>
                        <div v-for="avatar in from.avatars" v-if="avatar && from.avatars.length === 2" class="circle avatar mr-1" style="display: inline-block; width: 40px; height: 40px; line-height: 1; margin-top: 0px!important; margin-left: -9px!important;">
                            <img v-if="avatar.image" :src="avatar.image" style="width: 40px; height: 40px;">
                            <span v-else class="text-white circle" style="line-height: 42px; width: 40px;height: 40px; text-align: center; display: inline-block;" :style="{'background-color': avatar.color}">{{avatar.abbr.substring(0, 1)}}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="box">
                <i class="material-icons close" @click="$emit('hideGreeting')">keyboard_arrow_down</i>
                <div class="message" @click="$emit('greetingClicked')">
                    <div class="title" style="display: flex;">
                        <strong style="align-self: center;" class="truncate">{{ from.fromType === 'random' ? randomUserFromTeam.name : from.name }}</strong>
                    </div>

                    <p class="pt-2" v-html="getMessageHtml"></p>
                </div>
            </div>
        </div>

        <div v-if="!isMobile()" @click="$emit('greetingClicked')" class="box chat" :class="{'left': position === 'LEFT'}" style="border-radius: 18px; display: flex; margin-top: 20px; padding: 14px 0 14px 16px; cursor: text;">
            <span style="margin-top: 4px; flex-grow: 1;">
                {{widget_settings.translations.chat.write_a_reply}}
            </span>
            <i class="material-icons" style="cursor: pointer; color: #B7B7B7; padding-right: 0.7rem;">
                insert_emoticon
            </i>
            <i class="material-icons" style="cursor: pointer; padding-right: 1rem;" :style="{color: color}">
                send
            </i>
        </div>
    </div>
</template>
