<script type="text/babel">

    import preChatForm from './PreChatForm.vue';
    import chatOfflineForm from './ChatOfflineForm.vue';
    import email from './Email.vue';
    import facebook from './Facebook.vue';
    import telegram from './Telegram.vue';
    import whatsapp from './WhatsApp.vue';
    import twitter from './Twitter.vue';
    import sms from './Sms.vue';
    import voice from './Voip.vue';
    import helpCenter from './HelpCenter.vue';
    import isMobile from '../Util/IsMobile';
    import chatState from '../Util/ChatState';
    import Pusher from 'pusher-js';
    import idGenerator from '../Util/IdGenerator';
    import visitorToken from '../Util/VisitorToken';
    import _ from 'lodash'; _.noConflict();
    //import * as linkify from 'linkifyjs';
    import linkifyStr from 'linkifyjs/string';
    import api from '../Util/Api';
    import emailCapture from './EmailCapture.vue';
    import GifSelector from './Gif';
    import EmojiSelector from './EmojiSelector';
    import Branding from './Branding';
    import PanelHead from './PanelHead';
    import PanelTitle from './PanelTitle';
    import GifMessage from './GifMessage';
    import ButtonMessage from './ButtonMessage';
    import Metrics from '../Util/Metrics';
    import CobrowsingMessage from "./CobrowsingMessage";
    import Ga from "../Util/Ga";
    const { DateTime } = require("luxon");

    export default {

        components: {
            CobrowsingMessage,
            PanelTitle,
            PanelHead,
            preChatForm,
            chatOfflineForm,
            email,
            facebook,
            telegram,
            whatsapp,
            sms,
            twitter,
            voice,
            helpCenter,
            emailCapture,
            GifSelector,
            EmojiSelector,
            Branding,
            GifMessage,
            ButtonMessage,
        },

        data()
        {
            return {
                message: '',
                current_channel: '',
                status: "",
                searchString: '',
                ticket: {
                    status: null,
                    messages: [],
                    contact: {
                        email: null
                    },
                    agent: null
                },
                pusher: null,
                agentTyping: false,
                cdn_url: process.env.CDN_URL,
                white_bg: false,
                typingTimer: null,
                gifVisible: false,
                emojiVisible: false,
                dialpadVisible: false,
                botagentTyping: true,
                showShadow: false,
                presenceChannel: null,
                textBoxShadow: null,
                clientTypingTimer: null,
                panelHead: {isHelpCenter: false, helpCenterPage: ''},
                panelTitle: {title: '', subTitle: '', link: ''},
                helpCenterHistory: [],
                welcomeTimer: null,
                botTyping: false,
                botTypingTimer: null,
                browserCallAvailable: false,
                automation: false,
                lastIntervalTime: 0,
                // audioPlayerButton: (typeof Audio != 'undefined' ? new Audio('https://notificationsounds.com/soundfiles/58ae749f25eded36f486bc85feb3f0ab/file-sounds-1094-intuition.mp3') : null), // @todo save local
            }
        },

        computed: {
            panelIntroStyle() {
                let showTeamPhotos = typeof(this.widget_settings.team) !== 'undefined' && this.widget_settings.team.length > 0;
                let chatNotStarted = this.ticket.status !== 'ASSIGNED';
                let isOnHelpCenter = this.panelHead.isHelpCenter;

                if (isOnHelpCenter) {
                    return 'padding-top: 108px'
                }

                if (showTeamPhotos && chatNotStarted) {
                    return 'padding-top: 244px'
                }

                if (!showTeamPhotos && chatNotStarted) {
                    return 'padding-top: 155px'
                }

                if (!chatNotStarted) {
                    return 'padding-top: 108px'
                }
            },
            gifPickerEnabled() {
                return typeof(parent.Trengo.gifPicker) === 'undefined' || parent.Trengo.gifPicker !== false;
            },
            emojiPickerEnabled() {
                return typeof(parent.Trengo.emojiPicker) === 'undefined' || parent.Trengo.emojiPicker !== false;
            },
            filePickerEnabled() {
                return typeof(parent.Trengo.filePicker) === 'undefined' || parent.Trengo.filePicker !== false;
            },
            headerEnabled() {
                return typeof(parent.Trengo.panelHeader) === 'undefined' || parent.Trengo.panelHeader !== false;
            },
            invalidErrorMessage() {
                let languages = window.navigator.languages || [ window.navigator.language ];
                if (languages[0].toLowerCase().startsWith('nl')) {
                    return 'Incorrecte ingevulde gegevens voor ';
                } else {
                    return 'Invalid value for ';
                }
            },
            text()
            {
                if (!this.shouldDisplayApiLink()) {
                    let phone = this.widget_settings.channels.whatsapp.identifier;
                    return '+'+phone.substr(0,2)+' '+phone.substr(2, 1)+' '+phone.substr(3, 4)+' '+phone.substr(7, 4);
                }

                return this.widget_settings.translations.whatsapp.button;
            },
            link()
            {
                const phoneNumber = this.widget_settings.channels.whatsapp.identifier.replace(/\D/g, '');
                if (!this.shouldDisplayApiLink()) {
                    return 'tel:'+this.widget_settings.channels.whatsapp.identifier;
                }
                return 'https://api.whatsapp.com/send?phone='+phoneNumber
            },
            hrefTarget ()
            {
                return (!isMobile.any() ? "_blank" : "")
            }
        },

        props: [
            'color',
            'widget_settings',
            'greeting',
            'flowbot',
            'helpCenterArticle',
        ],

        watch: {
            message() {
                this.clientTypingTimerBounced();
            },

            dialpadVisible(v) {
                window.Trengo.eventBus.$emit('panel-state-changed', this.gifVisible || this.emojiVisible || this.dialpadVisible);

                this.$refs.scrollContainer[v ? 'addEventListener' : 'removeEventListener']('click', this.hidePanels);
            },

            gifVisible(v) {
                window.Trengo.eventBus.$emit('panel-state-changed', this.gifVisible || this.emojiVisible || this.dialpadVisible);

                this.$refs.scrollContainer[v ? 'addEventListener' : 'removeEventListener']('click', this.hidePanels);
            },

            emojiVisible(v) {
                window.Trengo.eventBus.$emit('panel-state-changed', this.gifVisible || this.emojiVisible || this.dialpadVisible);

                this.$refs.scrollContainer[v ? 'addEventListener' : 'removeEventListener']('click', this.hidePanels);
            },

            current_channel(val) {
                this.panelHead.isHelpCenter = (val === 'help_center');
            }
        },

        mounted() {

            /*if (isMobile.any()) {
                // this.$refs.panel.style.borderRadius = 0;
            }*/

            if (this.widget_settings.ticket != null) {
                this.ticket = this.widget_settings.ticket;
            }

            window.parent.addEventListener('online',  this.onOnline);
            //window.parent.addEventListener('offline', this.onOffline);

            // when user came here by pressing 'back' or 'forward' in the browser, we have to reload the messages // TODO fix: Safari only re-mount()'s every other time when navigating back
            let nav = window.parent.performance.getEntriesByType("navigation")[0];
            if ((nav && String(nav.type) === "back_forward") || window.parent.performance.navigation.type === 2) {
                this.onBackForward();
            }

            window.parent.document.addEventListener('visibilitychange', this.onVisibilityChange);

            this.initIntervalResume();

            if (this.ticket.status != null) {
                this.handleChatState();
            }

            if (this.ticket.status == null && chatState.getIsChatting()) {
                chatState.setIsNotChatting();
            }

            if (this.widget_settings.channels.chat) {
                this.current_channel = 'chat';
            }
            else {
                if (this.widget_settings.channels.whatsapp) {
                    this.current_channel = 'whatsapp';
                }
                else if (this.widget_settings.channels.facebook) {
                    this.current_channel = 'facebook';
                }
                else if (this.widget_settings.channels.telegram) {
                    this.current_channel = 'telegram';
                }
                else if (this.widget_settings.channels.twitter) {
                    this.current_channel = 'twitter';
                }
                else if (this.widget_settings.channels.email) {
                    this.current_channel = 'email';
                }
                else if (this.widget_settings.channels.help_center) {
                    this.current_channel = 'help_center';
                }
            }

            if (this.ticket.status != null && !isMobile.any()) {
                this.scrollToBottom(0);
                this.scrollToBottom(100);
            }

            Trengo.eventBus.$on('widget.open', (data) => {

                // Set smooth scrolling for IOS
                this.$nextTick(() => {
                    this.$refs.scrollContainer.style.webkitOverflowScrolling = 'touch';
                });

                if (this.current_channel == 'chat' && this.ticket.status == null) {
                    this.onChatWindowOpen(data ? data.intro_text : null);
                }

                if (data && data.channel && this.hasChannel(data.channel)) {
                    this.current_channel = data.channel;
                }

                if (this.helpCenterArticle.automationId && this.widget_settings.channels.help_center) {
                    this.automation = true
                    this.switchChannelTab('help_center')
                }
            });

            Trengo.eventBus.$on('widget.background.clear', () => {
                this.white_bg = true;
            });

            Trengo.eventBus.$on('widget.close', () => {
                this.$nextTick(() => {
                    this.$refs.scrollContainer.style.webkitOverflowScrolling = 'auto';
                });

                clearTimeout(this.welcomeTimer);
            });

            if (this.current_channel === 'chat') {
                this.$nextTick(() => {
                    this.scrollToBottom(0);
                });
            }
            // this.scrollToBottom(500);
        },

        methods: {
            onOnline() {
                //console.log('onOnline');
                this.reloadTicket();
            },

            onVisibilityChange() {
                //console.log('onVisibilityChange', window.parent.document.hidden ? 'hidden' : 'visible');
                if(!window.parent.document.hidden && isMobile.any()) {
                    this.reloadTicket();
                }
            },

            onBackForward() {
                //console.log('onBackForward');
                this.reloadTicket();
            },

            onIntervalResume() {
                //console.log('interval resume');
                this.reloadTicket();
            },

            initIntervalResume() {
                this.lastIntervalTime = (new Date()).getTime();
                window.setInterval(() => {
                    if(this.lastIntervalTime < ((new Date()).getTime() - 2000 * 2)) {
                        this.onIntervalResume();
                    }
                    this.lastIntervalTime = (new Date()).getTime();
                }, 2000);
            },

            reloadTicket() {
                if(this.chatIsStarted() && ((new Date()).getTime() - window.parent.Trengo.widgetLoaded) > 1500) {
                    this.$http.get(process.env.CDN_URL + '/widget', {
                        params: {
                            vt: visitorToken.getVisitorToken(),
                            path: window.location.pathname,
                            state: (chatState.getIsChatting() ? 'chat' : ''),
                            trengoKey: window.Trengo.key
                        }
                    }).then(res => {
                        if (res.data.error) {
                            console.error('Trengo widget error');
                            return;
                        }

                        this.ticket = res.data.ticket;
                    });
                }
            },

            onDialpadVisibilityChange(e) {
                this.dialpadVisible = e;
            },

            clientTypingTimerBounced: _.throttle(function() {
                this.broadcastTypingMessage();
            }, 750),

            broadcastTypingMessage() {
                if (this.presenceChannel === null) {
                    return;
                }
                this.presenceChannel.trigger('client-typing', {
                    message: this.message
                });
            },

            switchChannelTab(newChannel) {
                this.current_channel = newChannel;
                Trengo.eventBus.$emit('widget.switch.channel_tab', newChannel);
            },

            hidePanels() {
                this.emojiVisible = false;
                this.gifVisible = false;
            },

            hasChannel(channel) {
                return this.widget_settings.channels[channel] != null;
            },

            onChatWindowOpen(intro_text) {
                if (!this.widget_settings.channels.chat.pre_form && this.widget_settings.available) {
                    this.showWelcomeMessage(intro_text);
                }
            },

            closeWidget() {
                this.$emit('closeWidget');
            },

            handleChatState() {
                this.initPusher();
                this.initAutoClose();
            },

            send(e, gif_path = null, flowbot_step_id = null)  {

                if (e) {
                    if (e.shiftKey) {
                        return;
                    }
                    e.preventDefault();
                }

                if (this.widget_settings.preview) {
                    return;
                }

                if (this.message.trim() == '' && gif_path === null && flowbot_step_id === null) {
                    return;
                }

                if (this.handleEmailCapture()) {
                    return;
                }

                chatState.setIsChatting();

                var msgId = idGenerator.generateMessageId();

                let is_new = (this.ticket.messages).filter(m => { return !m.system }).length === 0;

                if (this.ticket.status == null) {
                    this.startChatWithoutPreForm();
                }

                let obj = {
                    message: this.message,
                    sent_at_time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
                    type: 'INBOUND',
                    uid: msgId,
                    body_type: 'TEXT'
                };

                if (gif_path !== null) {
                    obj['body_type'] = 'GIF';
                    obj['file_name'] = gif_path;
                }

                if (flowbot_step_id === null) {
                    this.ticket.messages.push(obj);
                }

                this.gifVisible = false;
                this.emojiVisible = false;

                this.$http.post('ticket', {
                    message: this.message,
                    vt: visitorToken.getVisitorToken(),
                    uid: msgId,
                    new: is_new,
                    locale: this.widget_settings.locale,
                    contact_data: window.Trengo.contact_data,
                    ticket_data: window.Trengo.ticket_data,
                    gif: gif_path,
                    vd: is_new ? chatState.getVisitorData() : null,
                    greeting: is_new && this.greeting.automationId ? {automationId: this.greeting.automationId, language: navigator.language || navigator.userLanguage} : null,
                    flowbot_step_id: flowbot_step_id,
                    flowbot_id: (is_new && this.flowbot) ? this.flowbot.flowbotId : null // flowbot set via widget_automation
                }).then(res => {
                    Ga.sendEventToGa(this.widget_settings.meta.send_ga, 'message sent');

                    this.ticket.contact.email = res.data.contact.email;
                    if (is_new) {
                        this.handleFirstMessageResponse(res);
                        if(this.flowbot && this.flowbot.flowbotId) {
                            Metrics.flowbot.start(this.flowbot.automationId);
                        }
                    }
                    window.Trengo.eventBus.$emit('update_last_contact');
                }).catch((e) => {
                    alert('Something went wrong. Please try again later.');
                });

                this.message = '';
                this.$nextTick(() => {
                    // this.$refs.composer.blur();
                    this.$refs.composer.style.height = '';
                });

                this.broadcastTypingMessage();
                this.scrollToBottom();
            },

            handleEmailCapture() {
                if (this.ticket.messages.length == 0) {
                    return false;
                }

                let latestMessage = this.ticket.messages[this.ticket.messages.length - 1];

                if (latestMessage.body_type != 'EMAIL_CAPTURE') {
                    return false;
                }

                if (!this.validateEmail(this.message)) {
                    return false;
                }

                if (latestMessage.body_type == 'EMAIL_CAPTURE' && this.ticket.contact.email == null) {
                    this.$refs.email_capture[0].setEmail(this.message);
                    this.message = '';
                    return true;
                }
            },

            onKeyUp(e) {
                e.target.style.height = '';
                e.target.style.height = Math.min(e.target.scrollHeight) + "px";
            },

            handleHeight(e) {
                e.target.style.height = '';
                e.target.style.height = Math.min(e.target.scrollHeight) + "px";
            },

            changeInputStyle(style) {
                if (style === 'focus') {
                    this.textBoxShadow = "0px 3px 12px " + this.hexToRGB(this.color);
                } else {
                    this.textBoxShadow = "0px 3px 12px rgba(0, 0, 0, 0.1)";
                }
            },

            scrollToBottom(timeout=10) {
                setTimeout(() => {
                   this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
                }, timeout);
            },

            onFocus(e) {
                var s = this;
                if (isMobile.Android()) {
                    if (this.firstFocus) {
                        //s.scrollToBottom(2000);
                        this.firstFocus = false;
                    }
                    else {
                        // s.scrollToBottom(300);
                    }
                }
            },

            processUploadedFile(e)  {
                if (this.widget_settings.preview) {
                    return;
                }

                var is_new = false;
                var msgId = idGenerator.generateMessageId();

                let data = new FormData();
                data.append('file', e.target.files[0]);
                data.append('uid', msgId);

                if (this.ticket.status == null) {
                    is_new = true;
                    data.append('new', 1);
                    this.startChatWithoutPreForm();
                }

                this.$http.post('ticket/media', data, {responseType: 'json', headers: { 'content-type': 'multipart/form-data' }}).then(res => {
                    _.filter(this.ticket.messages, message => { return message.uid == res.data.uid; })[0].sent_at_time = this.getLocalTime(res.data);
                    if (is_new) {
                        this.handleFirstMessageResponse(res);
                    }
                    window.Trengo.eventBus.$emit('update_last_contact');
                }).catch(res => {
                    if (res.response.status == 422) {
                        _.filter(this.ticket.messages, message => { return message.uid == msgId; })[0].sent_at_time = 'Failed';
                        alert(_.flatten(_.toArray(res.response.data.errors)).join(','));
                    } else {
                        alert('Something went wrong. Pease try again.');
                    }
                });

                var is_image = e.target.files[0]['type'].split('/')[0] == 'image';
                var reader = new FileReader();
                reader.onload = r => {
                    this.ticket.messages.push({
                        sent_at_time: this.widget_settings.translations.chat.sending,
                        type: 'INBOUND',
                        body_type: is_image ? 'IMAGE' : 'FILE',
                        file_name: e.target.files[0].name,
                        full_file_url: r.target.result,
                        uid: msgId
                    });
                    this.scrollToBottom(100);
                    this.gifVisible = false;
                    this.emojiVisible = false;
                };

                reader.readAsDataURL(e.target.files[0]);
            },

            showChatFooter() {
                return this.current_channel === 'chat' && (this.chatIsStarted() || (this.widget_settings.channels.chat.pre_form == 0 && this.widget_settings.available == true));
            },

            showChatClosedMessage() {
                return this.current_channel === 'chat' && this.chatIsClosed();
            },

            chatIsClosed() {
                return this.ticket != null && this.ticket.status === 'CLOSED';
            },

            getChatClosedMessage() {
                Trengo.eventBus.$emit('widget.chat.ended');
                var agent = this.ticket.agent.first_name || 'chat-bot';
                return this.widget_settings.translations.chat.chat_ended.replace(':agent', agent);
            },

            chatIsStarted() {
                return this.ticket.status != null;
            },

            showStartFlowbotButton() {
                return this.flowbotLinked() && this.ticket.status === null;
            },

            flowbotLinked() {
                return (this.widget_settings.flowbot && this.widget_settings.flowbot.active) || (this.flowbot && this.flowbot.flowbotId);
            },

            initPusher()  {
                if (this.pusher != null) {
                    return;
                }

                this.pusher = new Pusher(process.env.PUSHER_KEY, {
                    authEndpoint: process.env.CDN_URL+'/ticket/presence',
                    auth: {
                        params: {
                            vt: visitorToken.getVisitorToken(),
                            u: window.parent.location.href
                        }
                    },
                    cluster: 'eu'
                });

                this.presenceChannel = this.pusher.subscribe('presence-ticket@'+visitorToken.getVisitorToken());

                var channel = this.pusher.subscribe('ticket@'+visitorToken.getVisitorToken());

                channel.bind('RELOAD_TICKET', ticket => {

                    this.$set(this.ticket, 'status', ticket.status);
                    this.$set(this.ticket, 'agent', ticket.agent);

                    this.ticket.status = ticket.status;
                    // this.ticket.agent = ticket.agent;
                    if (ticket.status == 'CLOSED') {
                        this.onChatClosed();
                    }
                    else {
                        chatState.setIsChatting();
                    }
                    this.botTyping = false;
                });

                channel.bind('NEW_MESSAGE', message => {

                    this.agentTyping = false;

                    // Simulate chatbot
                    if (message.user_id == null && !message.silent) {
                        // this.botTyping = true;
                        this.scrollToBottom();
                        setTimeout(() => {
                            window.Trengo.eventBus.$emit('chat.message');
                            this.ticket.messages.push(message);
                            this.scrollToBottom();
                            this.botTyping = false;
                        }, 0);
                    }
                    else {
                        this.botTyping = false;
                        this.agentTyping = false;
                        window.Trengo.eventBus.$emit('chat.message');
                        this.ticket.messages.push(message);
                        this.scrollToBottom();

                        if (message.body_type === 'GIF') {

                        }
                    }
                });

                channel.bind('PRESENCE', data => {
                    clearTimeout(this.typingTimer);
                    this.typingTimer = setTimeout(() => {
                        this.agentTyping = false;
                    }, 10000);
                    this.agentTyping = data.typing;
                    this.scrollToBottom();
                });

                channel.bind('TYPING', data => {
                    clearTimeout(this.typingTimer);
                    clearTimeout(this.botTypingTimer);
                    this.botTypingTimer = setTimeout(() => {
                        this.botTyping = false;
                    }, 20000);
                    this.botTyping = true;
                    this.scrollToBottom();
                });

                this.initSurfly();
            },

            getMessage(message)  {
                var entityMap = {
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    '"': '&quot;',
                    "'": '&#39;',
                    '/': '&#x2F;',
                    '`': '&#x60;',
                    '=': '&#x3D;'
                };

                function escapeHtml (string) {
                    return String(string).replace(/[&<>"'`=\/]/g, function (s) {
                        return entityMap[s];
                    });
                }

                message = linkifyStr(message), {
                    defaultProtocol: 'http'
                };

                return message;
            },

            getAttachmentExtension(name) {
                let ext = name.split('.');
                if (ext.length > 1) {
                    return ext[ext.length - 1]
                } else {
                    return null;
                }
            },

            getAttachmentName(name) {
                let filename = name.split('-', 1);
                filename = name.substr(filename[0].length + 1);

                let ext = this.getAttachmentExtension(filename);
                // strip extension and dot
                if(ext) {
                    filename = filename.substr(0, filename.length - ext.length - 1);
                }

                return filename;
            },

            startChatWithPreForm(data)  {
                chatState.setIsChatting();
                this.ticket = data;
                this.handleChatState();
                api.triggerChatStartedEvent();

                setTimeout(() => {
                    //this.showWelcomeMessage();
                }, 100);
            },

            startChatWithoutPreForm() {
                this.ticket.status = 'OPEN';
                chatState.setIsChatting();
                this.initPusher();
                api.triggerChatStartedEvent();
            },

            onChatClosed() {
                this.scrollToBottom();
                Trengo.eventBus.$emit('badge.reset');
                chatState.setIsNotChatting();
                api.triggerChatEnded();
            },

            initAutoClose() {
                var auto_close_data = this.widget_settings.auto_close;

                if (auto_close_data != null && auto_close_data.enabled && this.ticket.status == 'OPEN') {



                    this.$http.get('/time').then(res => {

                        var now = (new Date(res.data.now.replace(' ', 'T'))).getTime();
                        var diff_in_seconds = parseInt(Math.abs( (now - (new Date(this.ticket.created_at.replace(' ', 'T'))).getTime()) / 1000));

                        if (auto_close_data.seconds < diff_in_seconds) {
                            this.autoCloseChat(auto_close_data);
                        }
                        else {
                            setTimeout(() => {
                                if (this.ticket.status == 'OPEN') {
                                    this.autoCloseChat(auto_close_data);
                                }
                            }, parseInt(auto_close_data.seconds - diff_in_seconds) * 1000);
                        }
                    });
                }
            },

            autoCloseChat() {
                this.$http.post('ticket/auto-close', {
                    guid: this.ticket['guid'],
                    message: this.widget_settings.translations.chat.auto_close_message
                });
                this.onChatClosed();
            },

            showWelcomeMessage(intro_text = null) {

                if (this.ticket.messages.length > 0) {
                    return;
                }

                let simulateagentTyping = intro_text == null;

                if (simulateagentTyping) {
                    this.botTyping = true;
                }

                if (this.flowbotLinked()) {
                    intro_text = this.flowbot.introText || this.widget_settings.flowbot.web_chat_start_message;
                    // this.botTyping = false;
                }

                this.welcomeTimer = setTimeout(() => {
                    this.botTyping = false;
                    this.ticket.messages.push({
                        message: intro_text || this.widget_settings.translations.chat.welcome_message,
                        sent_at_time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE),
                        type: 'OUTBOUND',
                        body_type: 'TEXT',
                        agent: null,
                        system: true
                    });
                }, simulateagentTyping ? 1000 : 0);
            },

            handleFirstMessageResponse(res)  {
                this.ticket.guid = res.data.ticket.guid;
                this.ticket.created_at = res.data.ticket.created_at;
                this.initAutoClose();

                if(this.greeting && this.greeting.automationId) {
                    Metrics.greeting.reply(this.greeting.automationId);
                }
            },

            restartChat() {
                window.parent.location.reload();
            },

            setEmail(data) {
                this.ticket.contact.email = data.email;
            },

            clearEmail() {
                this.ticket.contact.email = null;
            },


            insertGif(e, gif) {
                this.gifVisible = false;
                this.send(e, gif.images.fixed_height.url);
            },

            toggleEmojiPanel() {
                this.emojiVisible = !this.emojiVisible;
                this.gifVisible = false;
            },

            toggleGifPanel() {
                this.gifVisible = !this.gifVisible;
                this.emojiVisible = false;
            },

            insertEmoji(emoji) {
                this.emojiVisible = false;
                let txtarea = this.$refs.composer;
                let caretPos = txtarea.selectionStart;
                let front = (txtarea.value).substring(0, caretPos);
                let back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
                txtarea.value = front + emoji + back;
                this.message = txtarea.value;
                // txtarea.selectionStart = caretPos+1;
                // txtarea.selectionEnd = caretPos+1;
                txtarea.focus();
            },

            onContainerScroll(e) {
                this.showShadow = e.target.scrollTop > 10;
            },

            handleagentTyping() {
                this.presenceChannel.trigger('client-agentTyping', {
                    message: this.message
                });
            },

            getLocalTime(message) {
                if (typeof(message.timestamp) === 'undefined') {
                    return message.sent_at_time;
                }
                else {
                    return DateTime.fromSeconds(message.timestamp).toLocaleString(DateTime.TIME_24_SIMPLE);
                }
            },

            // https://stackoverflow.com/a/55561707/3344029 // duplicate in Note.vue
            isOnlyEmojis(str) {
                let ranges = [
                    '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
                    ' ', // Also allow spaces
                ].join('|');
                let removeEmoji = str => str.replace(new RegExp(ranges, 'g'), '');
                return !removeEmoji(str).length;
            },

            onGifLoaded() {
                this.scrollToBottom(0);
            },

            isChatbot() {
                if (!this.widget_settings.chatbot) {
                    return false;
                }

                if(this.current_channel !== 'chat') {
                    return false
                }

                return this.ticket.status === 'ASSIGNED' && (this.ticket.agent == null || this.ticket.agent.name == null);
            },

            isFlowbot() {
                if (this.isChatbot()) {
                    return false;
                }

                if(this.current_channel !== 'chat') {
                    return false
                }

                return this.ticket.status === 'ASSIGNED'  && (this.ticket.agent == null || this.ticket.agent.name == null);
            },

            startFlow(e) {
                this.send(e, null, this.flowbot.flowbotStepId || 1);
                this.$set(this.ticket, 'status', 'ASSIGNED');
                this.botTyping = true;
            },

            onButtonClicked(button) {
                if (this.status === 'CLOSED') {
                    return;
                }

                this.ticket.messages.pop();
                this.message = button.text;
                this.send(null);

                // if (this.audioPlayerButton) {
                //     this.audioPlayerButton.play();
                // }

                this.simulateBotTyping();

                //https://notificationsounds.com/soundfiles/58ae749f25eded36f486bc85feb3f0ab/file-sounds-1094-intuition.wav"
            },

            simulateBotTyping() {
                setTimeout(() => {
                    this.botTyping = true;
                    this.scrollToBottom();
                }, 350);
            },

            initSurfly() {
                if (!this.widget_settings.meta) {
                    return;
                }
                let key = this.widget_settings.meta.surfly_key;
                if (!key) {
                    return;
                }

                (function(d, script, t) {
                    script = d.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.src = 'https://surfly.com/surfly.js';
                    script.onload = () => {
                        let tries = 0;
                        const initSurfly = () => {
                            if(!parent.Surfly && tries < 5) {
                                setTimeout(initSurfly, ++tries * 1000);
                                return;
                            }
                            parent.Surfly.init({
                                block_until_agent_joins: false,
                                hide_until_agent_joins: true,
                                start_docked: true,
                                splash: false,
                                widget_key: key
                            }, function(initResult) {
                                if (initResult.success) {
                                    parent.Trengo.eventBus.$emit('surfly.ready');
                                }
                            })
                        };

                        setTimeout(initSurfly, 300);
                    };
                    d.getElementsByTagName('head')[0].appendChild(script);
                }(parent.document));
            },

            searchSubmit() {
                Trengo.eventBus.$emit('helpCenter.search', this.searchString);
            },

            shouldDisplayApiLink()
            {
                return true;
                // The WhatsApp API link only works on mobiles or Google Chrome Desktop
                var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
                return isMobile.any();
            },

            validateEmail(email) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },

            hexToRGB(h) {
                let r = 0, g = 0, b = 0;

                // 3 digits
                if (h.length == 4) {
                    r = "0x" + h[1] + h[1];
                    g = "0x" + h[2] + h[2];
                    b = "0x" + h[3] + h[3];

                    // 6 digits
                } else if (h.length == 7) {
                    r = "0x" + h[1] + h[2];
                    g = "0x" + h[3] + h[4];
                    b = "0x" + h[5] + h[6];
                }

                return "rgb("+ +r + "," + +g + "," + +b + "," + "0.2" + ")";
            },

            getFlowbotButtons() {
                try {
                    let latestMessage = this.ticket.messages[this.ticket.messages.length - 1];
                    if (latestMessage && latestMessage.body_type === 'BUTTONS') {
                        return latestMessage;
                    }
                    return false;
                }
                catch(e) {
                    return false;
                }
                return false;
            }
        }
    }
</script>

<template>
    <div class="panel">

        <div style="overflow-y: scroll; flex: 1; display: flex; flex-direction: column; overflow-x: hidden" ref="scrollContainer" @scroll="onContainerScroll($event)">

            <panel-title v-if="headerEnabled" v-bind="panelTitle" :panelHead="panelHead" :widget_settings="widget_settings" :ticket="ticket"></panel-title>

<!--            132-->
            <div class="panel-intro" :style="panelIntroStyle" v-if="headerEnabled"></div>
            <div class="panel-content flex">
                <div>
                    <div class="panel-body-content"  v-if="current_channel == 'chat'" >
                        <template v-if="ticket.status != null || widget_settings.channels.chat.pre_form == 0">
                            <div class="message-container" v-for="(message, i) in ticket.messages" :class="{ inbound: message.type == 'INBOUND'}" >

                                <div v-if="message.body_type != 'EMAIL_CAPTURE'" class="message " :class="{attachment: message.body_type == 'FILE', 'buttons': message.body_type == 'BUTTONS', image: message.body_type == 'IMAGE', outbound: message.type == 'OUTBOUND', inbound: message.type == 'INBOUND', email: message.type == 'EMAIL'}">
                                    <div class="flex" :class="{'float-right': message.type === 'INBOUND' }">

                                        <div v-if="message.type === 'OUTBOUND' && message.agent != null" class="">
                                            <span class="panel-team-avatar" style="margin-top: 12px; float: left; line-height: 40px; width: 40px; height:40px;;" :style="{ 'background-color': message.agent && !message.agent.profile_image ? (message.agent.color || color) : null }">
                                                <span v-if="message.agent && message.agent.profile_image">
                                                    <img style="width: 40px;" :src="message.agent.profile_image" />
                                                </span>
                                                <span v-else-if="message.agent" style="font-size: 15px;">
                                                    {{ message.agent.abbr.substr(0,1) }}
                                                </span>
                                            </span>
                                        </div>

                                        <div v-if="message.type === 'OUTBOUND' && message.agent == null && message.body_type !== 'BUTTONS'" class="">
                                            <span v-if="widget_settings.chatbot || (widget_settings.flowbot && widget_settings.flowbot.active)" class="panel-team-avatar" style="float: left; width: 40px; height:40px;" :style="{ 'background-color': color }">
                                                <img :src="widget_settings.meta.icon_url" style="width: 100%; height: 100%; object-fit: cover;" v-if="(widget_settings.meta || {}).icon_url">
                                                <img :src="cdn_url+'assets/chatbot.svg'" v-else>
                                            </span>
                                            <span v-else class="panel-team-avatar"  style="margin-top: 12px; float: left; line-height: 40px; width: 40px; height:40px;" :style="{ 'background-color': color }">
                                                <i class="material-icons" :class="{'material-icons chat-bubble': widget_settings.position === 'RIGHT'}" style="margin-top: 11px; font-size: 19px;">chat_bubble</i>
                                            </span>
                                        </div>


                                        <div v-if="message.body_type === 'IMAGE'" >
                                            <div class="message-inner" style="margin-left: 12px; box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;" :style="{background: (message.type == 'INBOUND' ? color : '') }">
                                                <a :href="message.full_file_url" target="_blank"><img :src="message.full_file_url" /></a>
                                                <div class="message-text" v-if="message.file_caption != null">
                                                    <span v-html="getMessage(message.file_caption)" class="file-caption"></span>
                                                </div>
                                            </div>
                                            <div class="message-time" v-if="message.body_type !== 'BUTTONS' && message.body_type === 'IMAGE'">
                                                <span>{{ getLocalTime(message) }}</span>
                                            </div>
                                        </div>

                                        <div v-if="message.body_type === 'GIF'" >
                                            <div class="message-inner" style="padding: 0;">
                                                <gif-message :message="message" @loaded="onGifLoaded"></gif-message>
                                            </div>
                                            <div class="message-time">
                                                <span>{{ getLocalTime(message) }}</span>
                                            </div>
                                        </div>


                                        <div v-if="message.body_type === 'VIDEO'" >
                                            <div class="message-inner"  :style="{background: (message.type == 'INBOUND' ? color : '') }" >
                                                <video controls style="width:100%;">
                                                    <source :src="message.full_file_url" type="video/mp4">
                                                </video>
                                                <div class="message-text" v-if="message.file_caption != null">
                                                    <span v-html="getMessage(message.file_caption)" class="file-caption"></span>
                                                </div>
                                            </div>
                                            <div class="message-time">
                                                <span>{{ getLocalTime(message) }}</span>
                                            </div>
                                        </div>

                                        <div v-if="message.body_type === 'FILE'">
                                            <a :href="message.full_file_url" target="_blank" style="overflow:hidden;" >
                                                <div class="message-inner"  :style="{background: (message.type == 'INBOUND' ? color : '') }">
                                                    <div class="attachment-file flex justify-center">
                                                        <span class="truncate">{{ getAttachmentName(message.file_name) }}</span>
                                                        <span v-if="getAttachmentExtension(message.file_name)">{{ '.'+getAttachmentExtension(message.file_name) }}</span>
                                                    </div>
                                                    <div v-if="message.file_caption" class="mt-2 message-text">
                                                        <span v-html="getMessage(message.file_caption)" class="" v-if="message.file_caption != null"></span>
                                                    </div>
                                                </div>
                                            </a>
                                            <div class="message-time">
                                                <span>{{ getLocalTime(message) }}</span>
                                            </div>
                                        </div>

                                        <div v-if="message.body_type === 'TEXT'">
                                            <div class="message-inner"  :style="{background: (message.type == 'INBOUND' ? color : '') }" >
                                                <div class="message-text">
                                                    <span v-html="getMessage(message.message)" :class="{bigEmoiji: isOnlyEmojis(message.message)}"></span>
                                                </div>
                                            </div>
                                            <div class="message-time">
                                                <span>{{ getLocalTime(message) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <email-capture :message="message" :color="color" :ticket="ticket" @email-set="setEmail" @email-cleared="clearEmail" ref="email_capture"></email-capture>
                                    <!--<div class="message" v-if="ticket.contact.email != null">-->
                                    <!--<div class="message-inner">-->
                                    <!--<span>{{ widget_settings.translations.chat.notification_set_success.replace(':email', ticket.contact.email) }}</span>-->
                                    <!--</div>-->
                                    <!--<time>{{ getLocalTime(message) }}</time>-->
                                    <!--</div>-->
                                </div>
                            </div>
                            <div class="message-container is-composing" v-if="agentTyping">
                                <div class="message">
                                    <div class="flex">
                                        <span class="panel-team-avatar" style="margin-top: 12px; float: left; line-height: 40px; width: 40px; height:40px; " :style="{ 'background-color': ticket.agent.fav ? null : ticket.agent.color }">
                                            <span v-if="ticket.agent && ticket.agent.fav">
                                                <img style="width: 50px;" :src="ticket.agent.fav" />
                                            </span>
                                            <span v-else-if="ticket.agent && ticket.agent.abbr" style="font-size: 15px;">
                                                {{ ticket.agent.abbr.substr(0,1) }}
                                            </span>
                                        </span>
                                        <div class="message-inner" >
                                            <span class="loading"><span :style="{background: color}" class="dot one"></span><span :style="{background: color}" class="dot two"></span><span :style="{background: color}" class="dot three"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="message-container is-composing" v-else-if="botTyping">
                                <div class="message">
                                    <div class="flex">
                                        <span class="panel-team-avatar"  style=" float: left; line-height: 40px; width: 40px; height:40px;" :style="{ 'background-color': color }" v-if="widget_settings.chatbot || (widget_settings.flowbot && widget_settings.flowbot.active)">
                                            <img :src="widget_settings.meta.icon_url" style="width: 100%; height: 100%; object-fit: cover;" v-if="(widget_settings.meta || {}).icon_url">
                                            <img :src="cdn_url+'assets/chatbot.svg'" v-else>
                                        </span>
                                        <span v-else class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height:40px;" :style="{ 'background-color': color }">
                                            <i class="material-icons" :class="{'material-icons chat-bubble': widget_settings.position === 'RIGHT'}" style="margin-top: 11px; font-size: 19px;">chat_bubble</i>
                                        </span>
                                        <div class="message-inner">
                                            <span class="loading"><span :style="{background: color}" class="dot one"></span><span :style="{background: color}" class="dot two"></span><span :style="{background: color}" class="dot three"></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <cobrowsing-message :ticket="ticket" v-if="ticket.status" :widget_settings="widget_settings" :color="color" :presenceChannel="presenceChannel"></cobrowsing-message>
                        </template>
                        <div class="chat-pre-form"  v-if="ticket.status == null && widget_settings.channels.chat.pre_form == 1 && widget_settings.available">
                            <pre-chat-form @chatStarted="startChatWithPreForm" :greeting="greeting"></pre-chat-form>
                        </div>
                        <div class="chat-pre-form"  v-if="!widget_settings.available">
                            <chat-offline-form></chat-offline-form>
                        </div>
                    </div>

                    <div class="panel-body-content" v-if="current_channel === 'email'">
                        <email></email>
                    </div>
                    <div class="panel-body-content" v-if="current_channel === 'facebook'">
                        <facebook></facebook>
                    </div>
                    <div class="panel-body-content" v-if="current_channel === 'telegram'">
                        <telegram></telegram>
                    </div>
                    <div class="panel-body-content" v-if="current_channel === 'whatsapp'">
                        <whatsapp></whatsapp>
                    </div>
                    <div class="panel-body-content" v-if="current_channel === 'twitter'">
                        <twitter></twitter>
                    </div>
                    <div class="panel-body-content" v-if="current_channel === 'sms'">
                        <sms></sms>
                    </div>
                    <div class="panel-body-content" v-show="current_channel === 'voice'" v-if="widget_settings.channels.voice" ref="voice">
                        <voice @dialpad-visible="onDialpadVisibilityChange" :color="color"></voice>
                    </div>
                    <div v-if="current_channel === 'help_center'">
                        <help-center :automation="automation" :help_center_article="helpCenterArticle" :widget_settings="widget_settings"></help-center>
                    </div>
                </div>

                <div class="mt-auto" ref="bottom">
                    <div style="text-align: center">


                        <div v-if="getFlowbotButtons() && current_channel == 'chat'" class="buttons" style="user-select: none">
                            <button-message @selected="onButtonClicked" :meta="getFlowbotButtons().meta.meta" :color="color"></button-message>
                        </div>

                        <a v-if="current_channel === 'whatsapp'" :href="link" class="panel-composer start-flow-button accessible-button" ref="link" :style="{ color: color, boxShadow: '0px 3px 12px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px',}">
                            {{ text }} 💬
                        </a>

                        <button @click="startFlow" class="panel-composer start-flow-button accessible-button" v-if="showChatFooter() && showStartFlowbotButton()" :style="{ color: color, boxShadow: '0px 3px 10px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px', }">
                            <a>{{ widget_settings.translations.chat.start_flow }} 💬</a>
                        </button>

                        <a :target="hrefTarget" v-if="current_channel === 'twitter'" :href="'https://twitter.com/'+widget_settings.channels.twitter.identifier" class="panel-composer start-flow-button accessible-button" :style="{ color: color, boxShadow: '0px 3px 10px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px', }">
                            {{ widget_settings.translations.twitter.button }} 💬
                        </a>

                        <a :target="hrefTarget" v-if="current_channel === 'facebook'" :href="'https://messenger.com/t/'+widget_settings.channels.facebook.identifier" class="panel-composer start-flow-button accessible-button" :style="{ color: color, boxShadow: '0px 3px 10px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px', }">
                            {{ widget_settings.translations.facebook.button }} 💬
                        </a>

                        <a :target="hrefTarget" v-if="current_channel === 'telegram'" :href="'https://telegram.me/'+widget_settings.channels.telegram.identifier" class="panel-composer start-flow-button accessible-button" :style="{ color: color, boxShadow: '0px 3px 10px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px', }">
                            {{ widget_settings.translations.telegram.button }} 💬
                        </a>

                        <a v-if="current_channel === 'voice' && !browserCallAvailable" :href="'tel:'+widget_settings.channels.voice.phone"  :style="{ color: color, boxShadow: '0px 3px 10px ' + hexToRGB(color), textDecoration: 'none', lineHeight: '50px', }" class="panel-composer start-flow-button accessible-button">
                            {{ widget_settings.translations.voice.button }} 💬
                        </a>

                        <div class="powered" :class="{ 'powered-padding': current_channel === 'chat' }">
                            <branding :widget_settings="widget_settings"></branding>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel-bottom" style="user-select: none; z-index: 1" :class="{'panel-bottom': current_channel === 'chat' || current_channel === 'help_center'}">
            <div v-if="panelHead.isHelpCenter">
                <form class="search-wrap" @submit.prevent="searchSubmit">
                    <input :style="{boxShadow: textBoxShadow}" @input="searchSubmit" type="text" @blur="changeInputStyle('default')" @focus.prevent="changeInputStyle('focus')" :placeholder="widget_settings.translations.help_center.search_placeholder || 'Search for articles or subjects...'" v-model="searchString" class="form-control search"/>
                    <i :style="{color: color}" class="material-icons search-icon">search</i>
                </form>
            </div>
            <div id="composer" :style="{boxShadow: textBoxShadow}" class="panel-composer" v-if="showChatFooter() && !chatIsClosed() && !showStartFlowbotButton() && !getFlowbotButtons()" style="flex-direction: row;max-width: 100%;">
                <div class="composer-area-container">
                    <textarea class="composer-area" ref="composer" @blur="changeInputStyle('default')" @focus="changeInputStyle('focus')" @keydown.enter="send" @input="handleHeight($event)" v-model="message" name="reply" :placeholder="widget_settings.translations.chat.write_a_reply"></textarea>
                </div>
                <div v-show="message == ''" v-if="gifPickerEnabled" style="z-index: 999999;" class="panel-composer-control">
                    <span @click="toggleGifPanel"><i class="material-icons" :style="{color: gifVisible ? color : null }" >gif</i></span>
                    <transition name="slide-fade-up">
                        <gif-selector v-if="gifVisible" @insertGif="insertGif" @close="gifVisible = false"></gif-selector>
                    </transition>
                </div>
                <div class="panel-composer-control panel-composer-control-emoji" style="z-index: 999999;" v-if="emojiPickerEnabled">
                    <span @click="toggleEmojiPanel()"><i class="material-icons composer" :style="{color: emojiVisible ? color : null }">insert_emoticon</i></span>
                    <transition name="slide-fade-up">
                        <emoji-selector v-if="emojiVisible" @insertEmoji="insertEmoji" @close="emojiVisible = false"></emoji-selector>
                    </transition>
                </div>
                <div v-show="message == ''" class="panel-composer-control" style="z-index: 999999;" v-if="filePickerEnabled">
                    <span>
                        <label for="upload-attachment">
                            <i class="material-icons composer">attach_file</i>
                            <input type="file" id="upload-attachment" @change="processUploadedFile" style="display: none;" />
                        </label>
                    </span>
                </div>
                <button v-show="message != ''" @click="send" :style="{color: color}" aria-label="Send" style="z-index: 999999;" class="panel-composer-control">
                    <span><i class="material-icons composer">send</i></span>
                </button>
            </div>

            <div v-if="showChatClosedMessage()" class="panel-footer flex-fixed">
                <div class="chat-closed">{{ getChatClosedMessage() }} <i @click="restartChat()" class="fa fa-refresh cursor-pointer"></i></div>
            </div>
        </div>
        <div class="panel-channels" v-if="Object.keys(widget_settings.channels).length > 1">
            <button class="panel-channel" v-if="widget_settings.channels.chat" aria-label="Chat" @click="switchChannelTab('chat')" :style="{'border-color': current_channel === 'chat' ? color : '' }" :class="{ active: current_channel === 'chat' }">
                <i :style="current_channel === 'chat' ? 'color: ' + color + '!important' : ''" class="material-icons" :class="{'material-icons chat-bubble': this.widget_settings.position === 'RIGHT'}">chat_bubble</i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.whatsapp" aria-label="WhatsApp" @click="switchChannelTab('whatsapp')" :style="{'border-color': current_channel === 'whatsapp' ? color : '' }" :class="{ active: current_channel === 'whatsapp' }">
                <i :style="current_channel === 'whatsapp' ? 'color: ' + color + '!important' : ''" class="fa fa-whatsapp" ></i>
            </button>
            <button class="panel-channel"  v-if="widget_settings.channels.facebook" aria-label="Facebook" @click="switchChannelTab('facebook')" :style="{'border-color': current_channel === 'facebook' ? color : '' }" :class="{ active: current_channel === 'facebook' }">
                <i :style="current_channel === 'facebook' ? 'color: ' + color + '!important' : ''" class="fa fa-facebook"></i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.telegram" aria-label="Telegram" @click="switchChannelTab('telegram')" :style="{'border-color': current_channel === 'telegram' ? color : '' }" :class="{ active: current_channel === 'telegram' }">
                <i :style="current_channel === 'telegram' ? 'color: ' + color + '!important' : ''" class="fa fa-paper-plane-o"></i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.twitter" aria-label="Twitter" @click="switchChannelTab('twitter')" :style="{'border-color': current_channel === 'twitter' ? color : '' }" :class="{ active: current_channel === 'twitter' }">
                <i :style="current_channel === 'twitter' ? 'color: ' + color + '!important' : ''" class="fa fa-twitter"></i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.sms" aria-label="SMS" @click="switchChannelTab('sms')" :style="{'border-color': current_channel === 'sms' ? color : '' }" :class="{ active: current_channel === 'sms' }">
                <i :style="current_channel === 'sms' ? 'color: ' + color + '!important' : ''" class="material-icons">smartphone</i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.email" aria-label="E-mail" @click="switchChannelTab('email')" :style="{'border-color': current_channel === 'email' ? color : '' }" :class="{ active: current_channel === 'email' }">
                <i :style="current_channel === 'email' ? 'color: ' + color + '!important' : ''" class="material-icons">email</i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.voice" aria-label="Call" @click="switchChannelTab('voice')" :style="{'border-color': current_channel === 'voice' ? color : '' }" :class="{ active: current_channel === 'voice' }">
                <i :style="current_channel === 'voice' ? 'color: ' + color + '!important' : ''" class="material-icons">phone</i>
            </button>
            <button class="panel-channel" v-if="widget_settings.channels.help_center" aria-label="Help center" @click="switchChannelTab('help_center')" :style="{'border-color': current_channel === 'help_center' ? color : '' }" :class="{ active: current_channel === 'help_center' }">
                <i :style="current_channel === 'help_center' ? 'color: ' + color + '!important' : ''" class="material-icons">help</i>
            </button>
        </div>
    </div>
</template>
