import Vue from "vue";
import Widget from "./Components/Widget.vue";
import axios from "axios";
import visitorToken from "./Util/VisitorToken";
import chatState from "./Util/ChatState";
import widgetCss from "./widget.scss";
import panelCss from "./panel.scss";
import launcherCss from "./launcher.scss";
import greeterCss from "./greeter.scss";
import onlineMessageCss from "./online-message.scss";
import badgeCss from "./badge.scss";
// Http driver
axios.defaults.baseURL = process.env.API_URL;
axios.defaults.headers.common["TrengoKey"] = window.Trengo.key;
axios.defaults.params = {
  vt: visitorToken.getVisitorToken(),
  path: window.parent.location.href,
};
Vue.prototype.$http = axios;

window.Trengo.Api = {};
window.Trengo.http = axios;

const container = document.createElement("div");
container.id = "trengo-web-widget";
container.appendChild(document.createElement("trengo-widget"));
window.parent.document.body.appendChild(container);

window.Trengo.eventBus = new Vue();

Vue.directive("color", function (el, binding) {
  el.style.color = binding.value;
  el.style.borderColor = binding.value;
});

Vue.prototype.validateEmail = function (email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 * Custom VueJS iframe
 */
Vue.component("VueIframe", {
  render(h) {
    return h("iframe", {
      on: { load: this.renderChildren },
    });
  },
  props: ["cssFile"],
  data() {
    return {
      panelOpen: false,
    };
  },
  methods: {
    renderChildren() {
      const children = this.$slots.default;

      const body = this.$el.contentDocument.body;
      const head = this.$el.contentDocument.head;

      var css = {
        panel: panelCss,
        badge: badgeCss,
        greeter: greeterCss,
        launcher: launcherCss,
        "online-message": onlineMessageCss,
      };

      const style = document.createElement("style");
      let scriptEl = window.parent.document.querySelector(
        "#trengo-widget-script"
      );
      if (scriptEl && scriptEl.getAttribute("nonce")) {
        style.setAttribute("nonce", scriptEl.getAttribute("nonce"));
      }

      style.innerHTML = css[this.cssFile].toString();

      const link = document.createElement("base");
      link.target = "_parent";

      head.appendChild(link);
      head.appendChild(style);

      const el = document.createElement("DIV");
      body.appendChild(el);
      const iApp = new Vue({
        name: "iApp",
        data: { children: Object.freeze(children) },
        render(h) {
          return h("div", this.children);
        },
      });
      iApp.$mount(el);
      this.iApp = iApp;

      window.Trengo.eventBus.$on("panel-state-changed", (state) => {
        this.panelOpen = state;
      });

      body.addEventListener("keyup", (e) => {
        if ((e.key === "Escape" || e.keyCode === 27) && !this.panelOpen) {
          Trengo.eventBus.$emit("widget.close");
        }
      });
    },
  },
});

/**
 * @param data
 */
function TrengoWidgetRender(data) {
  data.panelOpen = false;

  new Vue({
    el: window.parent.document.querySelector("#trengo-web-widget"),
    components: {
      "trengo-widget": Widget,
    },
    mounted() {
      const style = document.createElement("style");
      let scriptEl = window.parent.document.querySelector(
        "#trengo-widget-script"
      );
      if (scriptEl && scriptEl.getAttribute("nonce")) {
        style.setAttribute("nonce", scriptEl.getAttribute("nonce"));
      }
      style.innerHTML = widgetCss.toString();
      window.parent.document.body.appendChild(style);

      window.parent.document.body.addEventListener("keyup", (e) => {
        if (e.key === "Escape" || e.keyCode === 27 /* && !this.panelOpen*/) {
          Trengo.eventBus.$emit("widget.close");
        }
      });
    },
    data: data,
  });
}

if (window.Trengo.preview == 1) {
  TrengoWidgetRender({
    preview: true,
    widget: window.Trengo.preview_data,
  });
} else {
  if (window.TrengoShimData) {
    TrengoWidgetRender({
      widget: window.TrengoShimData,
    });
  } else {
    console.warn("Please update this widget by embedding the new code.");

    axios
      .get(process.env.API_URL + "/widget", {
        params: {
          vt: visitorToken.getVisitorToken(),
          path: window.location.pathname,
          state: chatState.getIsChatting() ? "chat" : "",
          trengoKey: window.Trengo.key,
        },
        transformRequest: [
          (data, headers) => {
            delete headers.common.TrengoKey;
            return data;
          },
        ],
      })
      .then((res) => {
        if (res.error) {
          console.log("Trengo widget error");
          return;
        }

        const widget_settings = res.data;
        if(!widget_settings.meta)  {
          widget_settings.meta = {};
        }

        TrengoWidgetRender({
          widget: res.data,
        });
      });
  }
}
