<script>
    export default {

        data()
        {
            return {
                score: null,
                voteKey: null,
                timer: null,
                prevScore: null
            }
        },

        props: ['title', 'article', 'widget_settings'],

        mounted() {
            this.voteKey = 'Trengo_'+this.article+'_s';

            this.score = window.localStorage.getItem(this.voteKey);

            window.addEventListener('resize', e => {this.score = window.localStorage.getItem(this.voteKey);})
        },

        methods: {

            guid() {
                function s4()
                {
                    return Math.floor((1 + Math.random()) * 0x10000)
                        .toString(16)
                        .substring(1);
                }
                return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
                    s4() + '-' + s4() + s4() + s4();
            },

            vote(score)
            {
                if (score === this.score) {
                    return;
                }

                this.prevScore = this.score;
                this.score = score;
                window.localStorage.setItem(this.voteKey, score);
                this.persistVote();
            },

            persistVote()
            {
                let keys = {
                    article: 'a',
                    score: 'b',
                    token: 'c',
                    prevScore: 'd',
                };

                let data = {};
                data[keys.article] = this.article;
                data[keys.score] = this.score;
                data[keys.token] = this.getVisitorToken();

                if (this.prevScore != null) {
                    data[keys.prevScore] = this.prevScore;
                }

                this.$http.post(this.widget_settings.channels.help_center.url+'/api/vote', data);
            },

            getVisitorToken()
            {
                let tokenKey = 'Trengo_'+this.article+'_t';

                var token = window.localStorage.getItem(tokenKey);
                if (token == null) {
                    token = this.guid();
                    window.localStorage.setItem(tokenKey, token);
                }

                return token;
            }
        }
    }
</script>

<template>
    <div class="vote" :class="{ voted: score != null }">
        <div style="position: relative; top: 24px">
            <h4 style="color: #9E9E9E; font-size: 23px; font-weight: 500" class="mb-2" v-text="title"></h4>
            <a @click="vote('positive')" :class="{active: score === 'positive' }" class=""><span>😃</span></a>
            <a @click="vote('neutral')" :class="{active: score === 'neutral' }" class=""><span>😐</span></a>
            <a @click="vote('negative')" :class="{active: score === 'negative' }" class=""><span>😞️</span></a>
        </div>
    </div>
</template>
