<script type="text/babel">

    import visitorToken from '../Util/VisitorToken'
    import VoipDialPad from './VoipDialPad';
    const { DateTime } = require("luxon");
    import { Device } from '@twilio/voice-sdk';

    export default
    {
        data() {
            return {
                device: null,
                token: null,
                calling: false,
                connection: false,
                muted: false,
                dialpadVisible: false,
                loading: false,
                agent: null,
                duration: 0,
                durationInterval: null,
                error: false,
                overlayTimer: null,
                overlay: null,
                durationString: ''
            }
        },

        props: [
            'color'
        ],

        components: {
            VoipDialPad,
        },

        mounted()
        {
            //this.call();
        },

        computed: {

            intro() {
                let phone = this.$parent.widget_settings.channels.voice.phone;
                let link = '<a href="tel:'+phone.replace(/\s/g,'')+'">'+phone+'</a>';
                return this.$parent.widget_settings.translations.voice.intro.replace('{phone}', link)
            },

            browserCallAvailable() {
                // Check setting
                if (!this.$parent.widget_settings.channels.voice.allow_calls) {
                    this.$parent.browserCallAvailable = false;
                    return false;
                }

                // Check HTTPS
                if (window.frameElement?.ownerDocument?.location?.protocol !== 'https:' ) {
                    this.$parent.browserCallAvailable = false;
                    return false
                }

                // Disable IE
                if (window.document.documentMode) {
                    this.$parent.browserCallAvailable = false;
                    return false;
                }

                this.$parent.browserCallAvailable = true;

                return true;
            }
        },

        watch: {
            dialpadVisible(v) {
                this.$emit('dialpad-visible', this.dialpadVisible);
            }
        },

        methods: {
            async call() {
                this.loading = true;
                const token = await this.setupToken();
                await this.setupDevice(token.data.token);
                this.onReady();
            },

            end() {
                if (!this.device) {
                    return;
                }
                this.device.disconnectAll();
            },

            setupToken() {
                return this.$http.post('voip/token')
            },

            setupDevice(token) {

                if(this.device) {
                    this.device.updateToken(token);
                    return;
                }

                this.device = new Device(token, {
                    edge: 'frankfurt',
                    closeProtection: true,
                    logLevel: 'error',
                    enableImprovedSignalingErrorPrecision: true,
                });

                if (this.device.audio.inputDevice === null) {
                    this.overlayTimer = setTimeout(this.renderOverlay, 250);
                    navigator.mediaDevices.getUserMedia({ audio: true })
                        .then(this.removeOverlay)
                        .catch(this.onMicDeclined)
                }
            },

            renderOverlay() {
                this.overlay = parent.document.createElement('div');
                this.overlay.style = 'position: fixed; top:0; left:0; right:0; bottom: 0; background: black; opacity: 0.8; z-index:2147483001; display: flex; align-items: center; justify-content: center; color: #ffffff; font-family: Arial; font-size: 20px;';
                // this.overlay.innerHTML = this.$parent.widget_settings.translations.voice.accept_mic;
                parent.document.body.appendChild(this.overlay);
                this.overlay.onclick = this.removeOverlay;
                setTimeout(this.removeOverlay, 10000);
            },

            removeOverlay() {
                try {
                    clearTimeout(this.overlayTimer);
                    parent.document.body.removeChild(this.overlay);
                }
                catch (e) {}
            },

            onMicDeclined() {
                this.removeOverlay();
                this.error = true;
            },

            async onReady() {
                this.connection = await this.device.connect({
                    params: {
                        type: 'WIDGET',
                        to: window.Trengo.key,
                        vt: visitorToken.getVisitorToken()
                    }
                });
                this.setupCallListener();

                this.connection.on('accept', this.onConnect);
                this.connection.on('disconnect', this.onDisconnect);
            },

            onConnect(connection) {
                this.calling = true;
                this.loading = false;
                this.removeOverlay();
            },

            onDisconnect() {
                this.connection.off('accept', this.onConnect);
                this.connection.off('disconnect', this.onDisconnect);
                
                this.connection = null;
                this.calling = false;
                this.muted = false;
                this.dialpadVisible = false;
                this.duration = 0;
                this.agent = null;
                clearInterval(this.durationInterval);
                this.durationString = '00:00:00';
            },

            toggleMute() {
                if (this.connection === null) {
                    return;
                }

                this.muted = !this.muted;
                this.connection.mute(this.muted )
            },

            onDtmfKey(key) {
                if (!key || this.connection === null) {
                    return
                }

                this.connection.sendDigits(""+key);
            },

            toggleDialPad() {
                this.dialpadVisible = !this.dialpadVisible;
            },

            setupCallListener() {
                this.$parent.initPusher();
                let channel = this.$parent.pusher.subscribe('voip@'+visitorToken.getVisitorToken());
                channel.bind('ConferenceStarted', this.onCallStarted);
            },

            onCallStarted(res) {
                if (this.agent !== null) {
                    return;
                }

                this.agent = res.user;
                this.duration = DateTime.now().set({hours:0, minutes: 0});
                this.durationString = '00:00:00';
                this.durationInterval = setInterval(() => {
                    this.duration = this.duration.plus({ seconds: 1 })
                    this.durationString = this.duration.toLocaleString(DateTime.TIME_24_WITH_SECONDS);
                }, 1000);
            },

            getTime() {
                return DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE);
            }
        },
    }
</script>

<template>

    <div class="tab tab-voice">
        <div class="message-container" style="margin-bottom: 0px" >
            <div class="message outbound">
                <div class="flex   ">
                    <div>
                        <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height: 40px;" :style="{ 'background-color': $parent.widget_settings.color }">
                            <span>
                                <i style="font-size: 19px; margin-top: 11px" class="material-icons">call</i>
                            </span>
                        </span>
                    </div>
                    <div class="">
                        <div class="message-inner">
                            <div class="message-text">
                                <span v-html="intro"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="message-container" v-if="browserCallAvailable">
            <div class="message outbound">
                <div class="flex">
                    <div>
                        <span class="panel-team-avatar"  style="float: left; line-height: 40px; width: 40px; height: 40px;" :style="{ 'background-color': $parent.widget_settings.color }">
                            <span>
                                <i style="font-size: 19px; margin-top: 11px" class="material-icons">call</i>
                            </span>
                        </span>
                    </div>
                    <div class="">
                        <div class="message-inner">
                            <div class="message-text">
                                <span>{{ $parent.widget_settings.translations.voice.form }}</span>
                            </div>
                        </div>
                        <div v-if="error" class="voip_call_error">
                            Browser calling is unavailable due to a missing speaker or microphone.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="slide-fade">
            <div class="message-container" v-if="agent !== null">
                <div class="message outbound">
                    <div class="flex " >
                        <div>
                           <span class="panel-team-avatar" style="float: left; line-height: 40px; width: 40px; height: 40px;" :style="{ 'background-color': agent && !agent.profile_image ? (agent.color || color) : color }">
                                <span v-if="agent.profile_image">
                                    <img style="width: 40px;" :src="agent.profile_image" />
                                </span>
                                <span v-else>
                                    {{ agent.abbr.substr(0,1) }}
                                </span>
                            </span>
                        </div>
                        <div class="">
                            <div class="message-inner">
                                <div class="message-text">
                                    <span>{{ durationString }}</span>
                                </div>
                            </div>
                            <div class="message-time" style="padding-left: 0;">
                                <span>{{agent.first_name }} - {{ getTime() }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="slide-fade-up">
            <voip-dial-pad v-if="dialpadVisible" @dtmf="onDtmfKey" @close="dialpadVisible = false"></voip-dial-pad>
        </transition>
        <div class="dial-buttons"  v-if="!error && browserCallAvailable">
            <button class="button-rounded button-rounded-green mr-3" :class="{'button-disabled': calling || loading || error }" @click="call"><i class="material-icons">call</i></button>
            <button class="button-rounded button-rounded-red mr-3" :class="{'button-disabled': !calling  || error }" @click="end"><i class="material-icons">call_end</i></button>
            <button class="button-rounded  mr-3" :class="{'button-disabled': !calling || error }" @click="toggleDialPad" ><i class="material-icons">dialpad</i></button>
            <button class="button-rounded  mr-3" :class="{'button-disabled': !calling || error }" @click="toggleMute"><i class="material-icons" v-text="!muted ? 'mic' : 'mic_off'"></i></button>
        </div>
    </div>


    <!--<div class="tab tab-phone">-->
        <!--<div class="message-container" >-->
           <!--<button @click="call">Call</button>-->
           <!--<button @click="end">End</button>-->
        <!--</div>-->
    <!--</div>-->
</template>
