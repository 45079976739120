<template>
    <div class="voip-panel-browser" >
        <!--<div class="panel-gif-browser-close" @click="close" style="position: absolute; right: 20px; top: 20px;">-->
            <!--<i class="material-icons" style="margin: 0; font-size: 25px;">voip-keypad-keyboard_arrow_down</i>-->
        <!--</div>-->

        <div class="voip-panel-browser-close" @click="close">
            <i class="material-icons">keyboard_arrow_down</i>
        </div>
        
        <div ref="container" class="flex items-center justify-center my-auto">
            
            <div class="voip-keypad" >
                <div @mouseover="hover = true; activeKey = 1" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 1 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 1)">
                    <span>1</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 2" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 2 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 2)">
                    <span>2</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 3" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 3 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 3)">
                    <span>3</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 4" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 4 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 4)">
                    <span>4</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 5" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 5 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 5)">
                    <span>5</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 6" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 6 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 6)">
                    <span>6</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 7" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 7 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 7)">
                    <span>7</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 8" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 8 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 8)">
                    <span>8</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 9" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 9 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 9)">
                    <span>9</span>
                    <span></span>
                </div>

                <div @mouseover="hover = true; activeKey = 10" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 10 ? $parent.$parent.widget_settings.color : '#B7B7B7' }" class="voip-keypad-key" @click="$emit('dtmf', '*')">
                    <span>*</span>
                </div>
                <div @mouseover="hover = true; activeKey = 11" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 11 ? $parent.$parent.widget_settings.color : '#000000' }" class="voip-keypad-key" @click="$emit('dtmf', 0)">
                    <span>0</span>
                    <span></span>
                </div>
                <div @mouseover="hover = true; activeKey = 12" @mouseleave="hover = false; activeKey = null" :style="{ color: hover && activeKey === 12 ? $parent.$parent.widget_settings.color : '#B7B7B7' }" class="voip-keypad-key" @click="$emit('dtmf', '#')">
                    <span>#</span>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    
    export default {
        

        data() {
            return {
                term: null,
                hover: false,
                activeKey: null
            }
        },
        
        mounted() {
            
        },
        methods: {


            close() {
                this.$emit('close');
            },
        }
    }
    
</script>
