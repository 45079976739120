<template>
    <div class="panel-gif-browser">
        <div class="panel-gif-browser-top">
            <div class="panel-gif-browser-search">
                <input ref="input" type="text" @keyup.esc="close" v-model="term" placeholder="Search GIPHY.." />
                <giphy-logo class="panel-gif-powered"></giphy-logo>
            </div>
            <div class="panel-gif-browser-close" @click="close">
                <i class="material-icons">keyboard_arrow_down</i>
            </div>
        </div>
        <div class="panel-gifs" ref="container">
            <div class="panel-gif-col">
                <img :src="gif.images.preview_gif.url" @click="insertGif($event, gif)" v-if="i % 2 === 0" v-for="(gif, i) in gifs" alt="">
            </div>
            <div class="panel-gif-col">
                <img :src="gif.images.preview_gif.url" @click="insertGif($event, gif)" v-if="i % 2 !== 0" v-for="(gif, i) in gifs" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    
    import GiphyLogo from './GiphyLogo';
    import isMobile from '../Util/IsMobile';
    
    export default {
        
        components: {
            GiphyLogo
        },

        data() {
            return {
                term: null,
                gifs: [],
                searchTimer: null,
                searching: true
            }
        },
        
        mounted() {
            this.term = '';
            if (!isMobile.any()) {
                this.$refs.input.focus();
            }
        },
        
        watch: {
            term() {
                this.$refs.container.scrollTop = 0;
                clearTimeout(this.searchTimer);
                this.searchTimer = setTimeout(() => {
                    this.searching = true;
                    this.$http.get('/gifs?term='+this.term).then(res => {
                        this.gifs = res.data;
                        this.searching = false;
                    }).catch(() => {
                        this.gifs = [];
                        this.searching = false;
                    })
                }, 250);
            }
        },
        
        methods: {
            insertGif(e, gif) {
                this.$emit('insertGif', e, gif);
            },
            close() {
                this.$emit('close');
            }
        }
    }
    
</script>
