<script type="text/babel">

    export default {

        data()
        {
            return {
                name: '',
                email: '',
                message: '',
                custom_data: {},
                sending: false,
                sent: false,
                nameTextBoxShadow: null,
                emailTextBoxShadow: null,
                messageTextBoxShadow: null,
                errors: []
            }
        },

        mounted() {
        },

        methods:  {

            submit()  {
                if (this.$parent.widget_settings.preview) {
                    return;
                }

                if (this.validate()) {

                    this.sending = true;

                    this.$http.post('email', {
                        name: this.name,
                        message: this.message,
                        email: this.email,
                        custom_field_data: this.custom_data,
                        url: parent.location.href
                    }).then(res => {
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.custom_data = {};
                        this.sending = false;
                        this.sent = true;
                    }).catch(() => {
                        alert('Something went wrong. Please try again.');
                        this.sending = false;
                    });
                }
            },
            validate() {
                this.errors = [];
                if (this.name.trim() === '') {
                    this.errors.push('name');
                }
                if (this.message.trim() === '') {
                    this.errors.push('message');
                }
                if (!this.$parent.validateEmail(this.email)) {
                    this.errors.push('email');
                }
                if (this.$parent.widget_settings.channels.email.custom_fields && this.$parent.widget_settings.channels.email.custom_fields.length) {
                    this.$parent.widget_settings.channels.email.custom_fields.forEach(field => {
                        if (field.required && (this.custom_data[field.title] || '').trim() === '') {
                            this.errors.push(field.title);
                        }
                    });
                }
                if (!this.errors.length) {
                    return true;
                }
            },
            handleHeight(e) {
                e.target.style.height = 'auto';
                e.target.style.height = Math.min(e.target.scrollHeight) + "px";
            },

            changeInputStyle(style, name) {
                switch (name) {
                    case "name":
                        if (style === 'focus') {
                            this.nameTextBoxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                        } else {
                            this.nameTextBoxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                        }
                        break;
                    case "email":
                        if (style === 'focus') {
                            this.emailTextBoxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                        } else {
                            this.emailTextBoxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                        }
                        break;
                    case "message":
                        if (style === 'focus') {
                            this.messageTextBoxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                        } else {
                            this.messageTextBoxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                        }
                }
            },
        }
    }
</script>

<template>
    <div class="mail">
        <form @submit.prevent="submit()" class="pt-2">
            <div class="form-group" >
                <div class="floating-label relative">
                    <input id="InputName" @blur="changeInputStyle('default', 'name')" @focus="changeInputStyle('focus', 'name')" :style="{color: errors.includes('name') ? '#e3342f!important' : '#000000!important', boxShadow: errors.includes('name') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : nameTextBoxShadow}" data-vv-rules="required" :class="{'is-danger': errors.includes('name') }" name="name" v-model="name" class="form-control" type="text" :placeholder="$parent.widget_settings.translations.email.name">
                    <i class="material-icons absolute text-base text-red" style="right: 0; bottom: 14px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('name')">error_outline</i>
                    <span class="sr-only" v-if="errors.includes('name')">{{ $parent.invalidErrorMessage }} "{{ $parent.widget_settings.translations.email.name }}"</span>
                </div>
            </div>
            <div class="form-group">
                <div class="floating-label relative">
                    <input id="InputEmail" @blur="changeInputStyle('default', 'email')" @focus="changeInputStyle('focus', 'email')" :style="{color: errors.includes('email') ? '#e3342f!important' : '#000000!important', boxShadow: errors.includes('email') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : emailTextBoxShadow}" :class="{'is-danger': errors.includes('email') }" name="email" v-model="email" class="form-control" type="text" :placeholder="$parent.widget_settings.translations.email.email">
                    <i class="material-icons absolute text-base text-red" style="right: 0; bottom: 14px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('email')">error_outline</i>
                    <span class="sr-only" v-if="errors.includes('email')">{{ $parent.invalidErrorMessage }} "{{ $parent.widget_settings.translations.email.email }}"</span>
                </div>
             </div>
            <div class="form-group" v-for="(field, i) in $parent.widget_settings.channels.email.custom_fields">
                <div class="floating-label relative">
                    <input :id="'InputEmail'+i" :class="{'is-danger': errors.includes(field.title) }" :name="field.title" v-model="custom_data[field.title]" class="form-control" type="text" :placeholder="field.title">
                    <i class="material-icons absolute text-base text-red" style="right: 0; bottom: 17px; margin-right: 12px; font-size: 24px;" v-if="errors.includes(field.title)">error_outline</i>
                    <span class="sr-only" v-if="errors.includes(field.title)">{{ $parent.invalidErrorMessage }} "{{ field.title }}"</span>
                 </div>
             </div>
            <div class="form-group" style="margin-bottom: 0px">
                <div :style="{boxShadow: errors.includes('message') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : messageTextBoxShadow}" style="margin-bottom: 0px" class="floating-label-textarea relative email">
                    <textarea id="InputText" @blur="changeInputStyle('default', 'message')" @focus="changeInputStyle('focus', 'message')" style="overflow: hidden; height: auto" :style="{color: errors.includes('message') ? '#e3342f!important' : '#000000!important'}" @input="handleHeight($event)" ref="textarea"  :class="{'is-danger': errors.includes('message') }" name="message" :placeholder="$parent.widget_settings.translations.email.message" v-model="message"  class="form-control email"></textarea>
                    <i class="material-icons absolute text-base text-red" style="right: 0; top: 16px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('message')">error_outline</i>
                    <span class="sr-only" v-if="errors.includes('message')">{{ $parent.invalidErrorMessage }} "{{ $parent.widget_settings.translations.email.message }}"</span>
                    <div class="submit-email-container">
                        <button type="submit" class="button button-success send-email" :class="{ 'button-loading' : sending }" :style="{background: $parent.widget_settings.color }">
                            <div class="submit-email-content">
<!--                                <span class="email-text">{{ $parent.widget_settings.translations.email.submit }}</span>-->
                                <i class="material-icons email-icon">send</i>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="form-sent" v-show="sent" >
            {{ $parent.widget_settings.translations.email.msg_sent }}
        </div>
    </div>
</template>
