<script type="text/babel">


    export default {

        data()
        {
            return {
                count: 0
            }
        },

        watch:
        {
            count(count)
            {
                //localStorage.setItem('Trengo_badgeCount', count);
            }
        },

        mounted()
        {
            Trengo.eventBus.$on('badge.increment', (c) => {
                this.count++;
            });

            Trengo.eventBus.$on('badge.decrement', () => {
                this.count--;
            });

            Trengo.eventBus.$on('badge.reset', () => {
                this.count = 0;
            });

            Trengo.eventBus.$on('badge.set', (count) => {
                this.count = count;
            });
        },

        methods:
        {
            closeWidget()
            {
                this.$emit('closeWidget');
            }
        }
    }
</script>

<template>
    <div class="badge" v-show="count > 0">{{ count }}</div>
</template>
