import Xor from './Xor';

const postSalt = 'kdOIUkjrfk4829(*3k&3kjf][';

const Metrics = {
    post(data) {
        // no greeting (automation Id) set
        if (!data.greeting) {
            return;
        }

        data.timestamp = (new Date()).getTime();

        Trengo.http.post(
            process.env.METRICS_URL + '/' + window.Trengo.key,
            Xor.encode(JSON.stringify(data), window.Trengo.key + postSalt),
            {
                headers: {
                    'Content-Type': 'text/plain',
                },
                transformRequest: [(data, headers) => {
                    delete headers.common.TrengoKey; // remove custom header to prevent extra CORS OPTIONS request
                    return data;
                }],
            }
        ).catch(e => console.log(e));
    },

    greeting: {
        open(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'greeting.open',
            });
        },
        show(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'greeting.show',
            });
        },
        reply(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'greeting.reply',
            });
        },
        bounce(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'greeting.bounce',
            });
        },
    },

    flowbot: {
        /*set(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'flowbot.set',
            });
        },*/
        start(automationId) {
            Metrics.post({
                greeting: automationId, // todo refactor aws-lambda: 'greeting' --> 'automation'
                event: 'flowbot.start',
            });
        },
    },

    helpCenter: {
        open(automationId) {
            Metrics.post({
                greeting: automationId,
                event: 'helpCenter.open',
            });
        }
    }
};

export default Metrics;
