<template>
    <div class="help-center-category">
        <div v-for="section in category.sections" class="section py-4" v-if="section.articles && section.articles.length">
            <h4 class="truncate title pb-2">{{$parent.getTranslation(section).title}}</h4>
            <ul class="list-reset pb-2">
                <transition-group name="slide-fade">
                    <li v-for="article in section.articles.slice(0, showMaxArticles[section.id] || 5)" :key="article.id" :style="'color: '+($parent.widget_settings.color || '#999')" class="article block truncate py-1">
                        <i class="material-icons pr-5">library_books</i><a href="#" @click.prevent="$parent.loadPage('articles/'+article.id+'-')">{{$parent.getTranslation(article).title}}</a>
                    </li>
                </transition-group>
            </ul>
            <span v-if="section.articles.length > 5" @click="showMore(section.id)" class="more px-2 cursor-pointer" :style="'background: '+($parent.widget_settings.color || '#999')">{{showMaxArticles[section.id] === 9999 ? '-' : '+'}}{{section.articles.length - 5}} {{$parent.widget_settings.translations.help_center.items || 'items'}}</span>
        </div>

        <div v-if="category.orphan_articles.length" class="section py-4">
            <ul class="list-reset pb-2">
                <li v-for="article in category.orphan_articles" :style="'color: '+($parent.widget_settings.color || '#999')" class="article block truncate py-1">
                    <i class="material-icons pr-5">library_books</i><a href="#" @click.prevent="$parent.loadPage('articles/'+article.id+'-')">{{$parent.getTranslation(article).title}}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "Category",

    props: {
        category: Object,
    },

    data() {
        return {showMaxArticles: []};
    },

    mounted() {
        this.$parent.setTitle(this.$parent.getTranslation(this.category).title, this.category.articles.length + ' ' + (this.$parent.widget_settings.translations.help_center.articles || 'articles'), this.$parent.getTranslation(this.category).slug); // TODO translation pluralization?
    },

    methods: {
        showMore(sectionId) {
            this.$set(this.showMaxArticles, sectionId, this.showMaxArticles[sectionId] === 9999 ? 5 : 9999);
        }
    },
}
</script>

<style scoped>

</style>