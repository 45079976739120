export default
{
    init(available)
    {
        window.Trengo.Api.Widget =
        {
            open: function(channel) {
                if (['whatsapp','telegram','twitter','email','facebook','chat', 'sms', 'voice','help_center'].indexOf(channel) == -1) {
                    console.error('Trengo: Invalid channel');
                    return;
                }

                Trengo.eventBus.$emit('widget.open', {
                    channel: channel
                });
            },
            close: function(channel) {
                Trengo.eventBus.$emit('widget.close');
            },
            clear_background: function() {
                Trengo.eventBus.$emit('widget.background.clear');
                Trengo.eventBus.$on('widget.open', () => {
                    Trengo.eventBus.$emit('widget.background.clear');
                })
            },
            is_chatting: function() {
                return localStorage.getItem('TrengoWidget_is_chatting') !== null;
            },
            render: function() {
                Trengo.eventBus.$emit('widget.render');
            },
            url_updated() {
                Trengo.eventBus.$emit('widget.greetings.url_updated');
            }
        };

        if (available && typeof(window.Trengo.on_status_online) != 'undefined') {
            window.Trengo.on_status_online();
        }

        if (!available && typeof(window.Trengo.on_status_offline) != 'undefined') {
            window.Trengo.on_status_offline();
        }

        if (typeof(window.Trengo.on_ready) != 'undefined') {
            setTimeout(() => {
                window.Trengo.on_ready();
            }, 250);
        }
    },

    triggerChatStartedEvent()
    {
        if (typeof(window.Trengo.on_chat_started) != 'undefined') {
            window.Trengo.on_chat_started();
        }
    },

    triggerChatEnded()
    {
        if (typeof(window.Trengo.on_chat_ended) != 'undefined') {
            window.Trengo.on_chat_ended();
        }
    },

    triggerWidgetOpenEvent()
    {
        if (typeof(window.Trengo.on_open) != 'undefined') {
            window.Trengo.on_open();
        }
    },

    triggerWidgetCloseEvent()
    {
        if (typeof(window.Trengo.on_close) != 'undefined') {
            window.Trengo.on_close();
        }
    }

}
