<template>
    <div class="select-none">
        <div class="panel-header-ball"  :style="!panelHead.isHelpCenter && typeof($parent.widget_settings.team) !== 'undefined' && ticket.status !== 'ASSIGNED' && $parent.widget_settings.team.length === 0 ? 'margin-top: -89px' : !panelHead.isHelpCenter && typeof($parent.widget_settings.team) !== 'undefined' && $parent.widget_settings.team.length > 0 && ticket.status !== 'ASSIGNED'  ? 'margin-top: -0px' : 'margin-top: -136px'" :class="{shadow: $parent.showShadow }"></div>

        <div class="panel-header" :class="{'is-chatting': getImage}">
            <transition name="fade">
                <div v-if="$parent.panelHead.helpCenterPage" class="back-button" @click="emitGoBack"><i class="material-icons">arrow_back</i></div>
            </transition>

            <span v-if="getImage">
                <img :alt="getTitle" :src="getImage" style="object-fit: cover;">
            </span>

            <div class="panel-title-wrapper">
                <h3 v-if="$parent.current_channel === 'chat' && ticket.status === 'ASSIGNED' && !$parent.isChatbot() && !$parent.isFlowbot()">{{ ticket.agent.name }}</h3>
                <h3 v-else>{{ getTitle }}</h3>

                <template v-if="$parent.current_channel === 'help_center'">
                    <a class="subtitle" v-if="link" :href="link" target="_blank" rel="noreferrer noopener">{{getSubTitle}}</a>
                    <div class="subtitle" v-else>{{getSubTitle}}</div>
                </template>
                <template v-else>
                    <div class="online-status">
                        <span v-if="getSubTitle" class="status-text">
                            {{getSubTitle}}
                        </span>
                    </div>
                </template>

                <div class="close-widget" @click="$parent.closeWidget">
                    <i class="material-icons">keyboard_arrow_down</i>
                </div>
            </div>
        </div>
        <div class="panel-intro-text">
            <panel-head :panelHead="panelHead"></panel-head>
        </div>
    </div>
</template>

<script>
    import PanelHead from './PanelHead';

    export default {
        name: "PanelTitle",

        components: {
            PanelHead
        },

        props: {title: String, subTitle: String, link: String, ticket: Object, widget_settings: Object, panelHead: Object },

        computed: {
            getTitle() {
                // helpcenter channel
                if(this.$parent.current_channel === 'help_center') {
                    return this.title;
                }

                // unassigned
                if(this.ticket.status !== 'ASSIGNED') {
                    return this.$parent.widget_settings.translations.general.title;
                }

                // not a chat channel
                if(this.$parent.current_channel !== 'chat') {
                    return this.$parent.widget_settings.translations.general.title;
                }

                // flowbot
                if(this.$parent.isFlowbot()) {
                    return this.$parent.widget_settings.translations.general.title;
                }

                // chatbot
                if(this.$parent.isChatbot()) {
                    return this.widget_settings.chatbot.name;
                }

                // agent name with fallback
                return this.ticket.agent.first_name || this.$parent.widget_settings.translations.general.title;
            },
            getSubTitle() {
                // helpcenter
                if(this.$parent.current_channel === 'help_center') {
                    return this.subTitle;
                }

                // ticket assigned and is chat channel
                if(this.ticket.status === 'ASSIGNED' && this.$parent.current_channel === 'chat') {
                    return 'Online'
                }

                // agents online
                if (this.widget_settings.available && this.widget_settings.agents_online) {
                    return 'Online'
                }

                // chatbot or flowbot
                if(this.$parent.isFlowbot() || this.$parent.isChatbot()) {
                    return 'Online'
                }

                return null;
            },
            getImage() {
                // image is only for chat channel
                if(this.$parent.current_channel !== 'chat') {
                    return null;
                }

                // image is only for assigned tickets
                if(this.ticket.status !== 'ASSIGNED') {
                    return null;
                }

                // chatbot image
                if(this.$parent.isChatbot()) {
                    return null;
                    // return (this.$parent.widget_settings.meta || {}).icon_url || this.$parent.cdn_url+'assets/chatbot.svg';
                }

                // image is not for flowbot
                if(this.$parent.isFlowbot()) {
                    return null;
                }

                // agent image
                return this.ticket.agent.fav || this.$parent.cdn_url+'assets/blank.svg'
            }
        },

        mounted() {
            if(this.ticket.status === 'ASSIGNED' && this.$parent.current_channel === 'chat' || this.widget_settings.available && this.widget_settings.agents_online || this.$parent.isFlowbot() || this.$parent.isChatbot()) {
                Trengo.eventBus.$emit('widget.status', 'chat online');
            } else {
                Trengo.eventBus.$emit('widget.status', 'chat offline');
            }
        },

        methods: {
            emitGoBack() {
                Trengo.eventBus.$emit('helpCenter.goBack');
            },

        }
    }
</script>

<style scoped>

</style>
