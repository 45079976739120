<template>
    <a v-if="widget_settings.powered_by.text != null"  style="margin: 10px 0;" :href="widget_settings.powered_by.website" target="_blank" v-html="getPowered()"></a>
</template>

<script>
    export default {
        props: ['widget_settings'],
        methods: {
            getPowered()  {
                return this.widget_settings.powered_by.text+'     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 49.7"><defs><style>.a{isolation:isolate;}.b{fill:#999;mix-blend-mode:hard-light;}</style></defs><title>trengo-widget</title><g class="a"><path class="b" d="M32.67,7.53c-49.49,4.34-33,29.56-14,40.65C38,59.46,47,61.53,58.21,44.05,58.21,44.05,82.16,3.2,32.67,7.53Zm-12.38,24a2.92,2.92,0,0,1,2.92-2.91H37.85a2.92,2.92,0,1,1,0,5.83H23.21A2.92,2.92,0,0,1,20.29,31.57ZM47,42.82H27.3a2.92,2.92,0,0,1,0-5.83H47a2.92,2.92,0,1,1,0,5.83Zm0-16.66H18.42a2.92,2.92,0,1,1,0-5.83H47a2.92,2.92,0,1,1,0,5.83Z" transform="translate(0 -7.21)"/></g></svg>';
            }
        }
    }
</script>
