<template>
    <img :src="message.file_name" ref="image" class="gif_image" />
</template>

<script>
    export default {
        props: ['message'],
        
        mounted() {
            this.$refs.image.onload = () => {
                this.$emit('loaded');
            };
        }
    }
</script>