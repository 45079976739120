<template>
    <div class="panel-head" style="overflow: hidden;">
        <template v-if="!panelHead.isHelpCenter">
            <p v-if="$parent.ticket.status !== 'ASSIGNED'" style="min-height: 54px;">{{ $parent.widget_settings.translations.general.heading }}</p>
            <div class="panel-team" :class="{'panel-team-mobile':mobile, 'panel-team-full':typeof($parent.widget_settings.team) !== 'undefined' && $parent.widget_settings.team.length === 5 }" v-show="typeof($parent.widget_settings.team) !== 'undefined' && $parent.widget_settings.team.length > 0 && $parent.ticket.status !== 'ASSIGNED'">
                <div v-for="user in $parent.widget_settings.team" class="panel-team-user">
                    <span class="panel-team-avatar" style="margin-top: 0" :style="{ 'background-color': user.image || user.color || color, borderRadius: '18px'}">
                        <span v-if="user.image">
                            <img :src="user.image" />
                        </span>
                        <span v-else>
                            {{ user.abbr.substr(0,1) }}
                        </span>
                    </span>
                    <div class="panel-team-agent">{{ user.first_name }}</div>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
    import isMobile from '../Util/IsMobile';
    export default {
        name: "PanelHead",
        data() {
            return {
                mobile: false
            }
        },
        props: {
            panelHead: Object,
        },
        mounted() {
            this.mobile = isMobile.any();
        }
    }
</script>
