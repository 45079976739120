<script type="text/babel">

    import launcher from './Launcher.vue'
    import greeter from './Greeter.vue'
    import panel from './Panel.vue'
    import badge from './Badge.vue'
    import onlineMessage from './OnlineMessage.vue'
    import isMobile from '../Util/IsMobile';
    import api from '../Util/Api';
    import automation from '../Util/Automation';
    import Metrics from '../Util/Metrics';
    import Ga from '../Util/Ga';

    export default {
        components: {
            launcher,
            panel,
            greeter,
            badge,
            onlineMessage
        },


        data()
        {
            return {
                showGreeting: false,
                greeting: {automationId: null, message: '', from: {name: '', avatars: []}},
                flowbot: {automationId: null, flowbotId: null, flowbotStepId: null, autoStart: false},
                helpCenter: {automationId: null, articleId: null},
                showBadge: 0,
                isOpen: false,
                audioPlayer: (typeof Audio != 'undefined' ? new Audio(process.env.CDN_URL +'assets/chat.mp3') : null),
                title: document.title,
                titleCount: 0,
                render: false,
                isMobile: isMobile,
                greeterElement: null,
                pageUrlUpdatedTimer: null,
                Trengo: window.Trengo
            }
        },

        mounted()
        {
            if (window.Trengo.render !== false) {
                this.render = true;
            }
            else {
                Trengo.eventBus.$on('widget.render', (data) => {
                    this.render = true;
                });
            }

            var b = parent.document.getElementsByTagName("body")[0];

            var originalBodyStyle ={
                position: b.style.position,
                overflow: b.style.overflow,
                width: b.style.width,
            };

            let headMetaViewport = document.createElement('meta');
            headMetaViewport.id = 'trengo-viewport-meta';
            headMetaViewport.name = 'viewport';
            headMetaViewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';

            Trengo.eventBus.$on('widget.status', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, data)
            });

            Trengo.eventBus.$on('widget.chat.ended', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, 'chat ended')
            });

            Trengo.eventBus.$on('widget.email.set', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, 'email provided')
            });

            Trengo.eventBus.$on('widget.switch.channel_tab', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, data + ' opened')
            });

            Trengo.eventBus.$on('widget.chat.started', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, 'chat started')
            });

            Trengo.eventBus.$on('widget.open', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, 'opened')

                if(this.showGreeting) {
                    Metrics.greeting.open(this.greeting.automationId);
                }

                localStorage.setItem('Trengo_panelState', 'open');
                Trengo.eventBus.$emit('badge.reset');
                this.showGreeting = false;
                this.isOpen = true;

                api.triggerWidgetOpenEvent();

                if (isMobile.any()) {

                    setTimeout(() => {
                        b.style.width = '100%';
                        b.style.overflow = "hidden";
                        b.style.position = "fixed";
                    }, 500);
                    window.parent.document.getElementsByTagName("head")[0].appendChild(headMetaViewport);
                }
            });

            Trengo.eventBus.$on('widget.close', (data) => {
                Ga.sendEventToGa(this.$root.widget.meta.send_ga, 'closed');

                localStorage.setItem('Trengo_panelState', 'closed');
                this.showGreeting = false;
                this.isOpen = false;

                api.triggerWidgetCloseEvent();

                if (isMobile.any()) {
                    b.style.overflow = originalBodyStyle.overflow;
                    b.style.position = originalBodyStyle.position;
                    b.style.width = originalBodyStyle.width;

                    window.parent.document.getElementsByTagName("head")[0].removeChild(headMetaViewport);
                }
            });

            Trengo.eventBus.$on('chat.message', () => {
                if (!document.hasFocus()) {
                    this.titleCount++;
                    document.title = '('+this.titleCount+') '+this.title;
                }

                if (!this.isOpen || !document.hasFocus()) {

                    if (!this.isOpen) {
                        Trengo.eventBus.$emit('badge.increment');
                    }

                    if (this.audioPlayer) {
                        try {
                            this.audioPlayer.play().catch(() => {});
                        } catch (e) {}
                    }
                }
            });

            this.initBadgeEvents();
            this.handleState();

            window.addEventListener("focus", () => {
                this.titleCount = 0;
                document.title = this.title;
            });


            if (this.$root.preview) {
                this.handlePreviewModus();
            }

            api.init(this.$root.widget.available);

            // show greeting on event
            Trengo.eventBus.$on('greeting.show', (e) => {
                this.$set(this.greeting, 'automationId', e.automationId);
                this.$set(this.greeting, 'message', e.message);
                this.$set(this.greeting, 'from', e.from);
                this.handleGreeting();
            });

            // set flowbot on event
            Trengo.eventBus.$on('flowbot.set', (e) => {
                this.$set(this.flowbot, 'automationId', e.automationId);
                this.$set(this.flowbot, 'flowbotId', e.flowbotId);
                this.$set(this.flowbot, 'introText', e.introText);
                this.$set(this.flowbot, 'flowbotStepId', e.flowbotStepId || null);
                this.$set(this.flowbot, 'autoStart', e.autoStart || false);
                //Metrics.flowbot.set(e.automationId);
            });

            // open help center article
            Trengo.eventBus.$on('helpCenter.open', (e) => {
                if (!this.$root.widget.channels.help_center) {
                    return
                }

                this.$set(this.helpCenter, 'automationId', e.automationId);
                this.$set(this.helpCenter, 'articleId', e.articleId);
                Metrics.helpCenter.open(this.helpCenter.automationId);
                Trengo.eventBus.$emit('widget.open');
            });

            // re-init greetings on event, for SPA's
            Trengo.eventBus.$on('widget.greetings.url_updated', () => {
                clearTimeout(this.pageUrlUpdatedTimer);
                this.pageUrlUpdatedTimer = setTimeout(() => {
                    automation.init(this.$root.widget.automation);
                }, 1000);
            });

            automation.init(this.$root.widget.automation);
        },

        computed: {
            cssVars() {
                return {
                    '--bg-color': this.$root.widget.color
                }
            },
            nonce() {
                let scriptEl = document.querySelector('#trengo-widget-script');
                if(scriptEl && scriptEl.getAttribute('nonce')) {
                    return scriptEl.getAttribute('nonce');
                }
            },
            showOnlineMessage() {
                return this.$root.widget.show_online_status && !this.isOpen && !this.showGreeting && this.$root.widget.available
            },
            getGreetingMessage() {
                const lang = (navigator.language || navigator.userLanguage);

                let message = this.greeting.message.find(v => lang.startsWith(v.language));

                if(!message) {
                    message = this.greeting.message.find(v => v.default);
                }

                return message.message || ''
            }
        },

        methods:  {
            handlePreviewModus()
            {
                Trengo.eventBus.$emit('widget.open', {
                    channel: this.$root.widget.default_channel
                });

            },

            handleState()
            {
                if (this.$root.widget.ticket && localStorage.getItem('Trengo_panelState') != 'closed' && !isMobile.any()) {
                    Trengo.eventBus.$emit('widget.open');
                }
            },

            togglePanel()
            {
                if (!this.isOpen) {
                    let params = {channel: this.$root.widget.default_channel};

                    if(this.showGreeting) {
                        params.intro_text = this.getGreetingMessage;
                    }

                    Trengo.eventBus.$emit('widget.open', params);

                }
                else {
                    Trengo.eventBus.$emit('widget.close');
                }
            },

            closeWidget()
            {
                Trengo.eventBus.$emit('widget.close');
            },

            hideGreeting()
            {
                if(this.showGreeting) {
                    Metrics.greeting.bounce(this.greeting.automationId);
                }
                window.localStorage.setItem('Trengo_widgetClosedTime',+new Date);
                Trengo.eventBus.$emit('badge.reset');
                this.showGreeting = false
            },

            onGreetingClicked()
            {
                Trengo.eventBus.$emit('widget.open', {
                    intro_text: this.getGreetingMessage,
                    channel: 'chat'
                });
            },

            handleGreeting()
            {
                if (this.greeting) {
                    // instant greeting

                    // If another greeting is already visible, remove first
                    if (this.showGreeting) {
                        this.showGreeting = false;
                        setTimeout(() => {
                            this.renderGreeting();
                        }, 500);
                    }
                    else {
                        this.renderGreeting();
                    }

                }
            },

            renderGreeting() {
                if (this.isOpen || this.$root.widget.ticket != null || (this.$root.widget.channels.chat && !this.$root.widget.available)) {
                    return;
                }

                if (window.localStorage.getItem('Trengo_widgetClosedTime')) {
                    var difference = (new Date()).getTime() - new Date(parseInt(window.localStorage.getItem('Trengo_widgetClosedTime'))).getTime();
                    var resultInMinutes = Math.round(difference / 60000);
                    if (resultInMinutes < 10) {
                        return;
                    }
                }

                this.showGreeting = true;
                Metrics.greeting.show(this.greeting.automationId);

                Trengo.eventBus.$emit('badge.set', 1);

                if (this.audioPlayer) {
                    try {
                        this.audioPlayer.play().catch(() => {});
                    } catch (e) {}
                }

                this.$nextTick(this.setGreeterIframeHeight);
            },

            setGreeterIframeHeight() {
                let p = window.parent.document.querySelector('div.TrengoWidgetGreeter__iframe');

                this.getGreeterElement().then((g) => {
                    p.style.height = (this.greeterElement.offsetHeight + 150) + 'px';
                }).catch(e => {
                    console.log(e.message);
                });
            },

            getGreeterElement() {
                return new Promise((resolve, reject) => {
                    var p = window.parent.document.querySelector('div.TrengoWidgetGreeter__iframe');
                    var i = p.querySelector('iframe');

                    // wait for .greeter
                    this.greeterElement = i.contentWindow.document.body.querySelector('.greeter');
                    let allTimeouts = [];
                    for(let tries = 0; tries < 20; tries++) {
                        allTimeouts.push(setTimeout(() => {
                            this.greeterElement = i.contentWindow.document.body.querySelector('.greeter');

                            if(this.greeterElement) {
                                // clear timeouts
                                for(let i = 0; i < allTimeouts.length; i++) {
                                    clearTimeout(allTimeouts[i]);
                                }
                                return resolve(this.greeterElement);
                            } else if(tries === 19) {
                                // last try

                                // clear timeouts
                                for(let i = 0; i < allTimeouts.length; i++) {
                                    clearTimeout(allTimeouts[i]);
                                }
                                return reject(Error("Couldn't set iframe height for greeting."));
                            }
                        }, 50 * (tries + 1)));
                    }
                });
            },

            initBadgeEvents() {
                Trengo.eventBus.$on('badge.increment', () => {
                    this.showBadge = true;
                });

                Trengo.eventBus.$on('badge.reset', () => {
                    this.showBadge = false;
                });

                Trengo.eventBus.$on('badge.set', (c) => {
                    if (c < 1) {
                        return;
                    }
                    this.showBadge = true;
                });

                if (localStorage.getItem('Trengo_badgeCount')) {
                    setTimeout(() => {
                        if (!this.isOpen) {
                            Trengo.eventBus.$emit('badge.set', localStorage.getItem('Trengo_badgeCount'));
                        }
                    }, 500);
                }
            },

            onChatAssigned(agent) {

            }
        }
    }
</script>

<template>
    <div v-if="render">
        <div class="TrengoWidgetLauncher__iframe" :class="{'TrengoWidget--position-left': $root.widget.position === 'LEFT' }" :style="{[$root.widget.position === 'LEFT' ? 'margin-left' : 'margin-right']: Trengo.extraOffsetX, 'margin-bottom': Trengo.extraOffsetY}">
            <vue-iframe :nonce="nonce" class="trengo-vue-iframe" css-file="launcher" title="trengo-widget-launcher">
                <launcher @toggle="togglePanel" :flowbot="$root.widget.flowbot" :position="$root.widget.position" :isOpen="isOpen" :color="$root.widget.color" :icon_url="($root.widget.meta || {}).icon_url" :channels="$root.widget.channels" :chatbot="!!$root.widget.chatbot || !!($root.widget.flowbot && $root.widget.flowbot.active)"></launcher>
            </vue-iframe>
            <div v-if="!($root.widget.meta || {}).icon_url && !$root.widget.chatbot && !$root.widget.flowbot && !$root.widget.flowbot.active" class="launcher-triangle" :class="{'launcher-triangle-left': $root.widget.position === 'LEFT'}" :style="{'border-top-color': $root.widget.color}"/>
        </div>

        <transition name="fade">
            <div class="TrengoWidgetGreeter__iframe" v-if="greeting && showGreeting" :class="{'TrengoWidget--position-left': $root.widget.position === 'LEFT' }" :style="{[$root.widget.position === 'LEFT' ? 'margin-left' : 'margin-right']: Trengo.extraOffsetX, 'margin-bottom': Trengo.extraOffsetY}">
                <vue-iframe :nonce="nonce" class="trengo-vue-iframe" css-file="greeter" ref="greetingIframe" title="trengo-widget-greeter">
                    <greeter @hideGreeting="hideGreeting" @greetingClicked="onGreetingClicked" :position="$root.widget.position" :color="$root.widget.color" :message="getGreetingMessage" :from="greeting.from" :widget_settings="$root.widget"></greeter>
                </vue-iframe>
            </div>
        </transition>

        <div class="TrengoWidgetBadge__iframe" v-show="showBadge"  :class="{'TrengoWidget--position-left': $root.widget.position ==='LEFT' }" :style="{[$root.widget.position === 'LEFT' ? 'margin-left' : 'margin-right']: Trengo.extraOffsetX, 'margin-bottom': Trengo.extraOffsetY}">
            <vue-iframe :nonce="nonce" class="trengo-vue-iframe" css-file="badge" title="trengo-widget-badge">
                <badge></badge>
            </vue-iframe>
        </div>

        <div class="TrengoOnlineStatus__iframe" v-show="showOnlineMessage" :class="{'TrengoWidget--position-left': $root.widget.position === 'LEFT' }" :style="{[$root.widget.position === 'LEFT' ? 'margin-left' : 'margin-right']: Trengo.extraOffsetX, 'margin-bottom': Trengo.extraOffsetY}">
            <vue-iframe :nonce="nonce" class="trengo-vue-iframe" css-file="online-message" title="trengo-widget-status">
                <online-message :color="$root.widget.color" :message="$root.widget.translations.chat.online_status || 'How can we help you?'" :position="$root.widget.position"></online-message>
            </vue-iframe>
        </div>

        <transition name="">
            <div v-show="isOpen || showGreeting" class="trengo-shadow"  :class="{'TrengoWidget--position-left': $root.widget.position === 'LEFT' }"></div>
        </transition>

        <transition name="fade">
            <div class="TrengoWidgetPanel__iframe" v-show="isOpen" :class="{'TrengoWidget--position-left': $root.widget.position === 'LEFT' }" :style="{[$root.widget.position === 'LEFT' ? 'margin-left' : 'margin-right']: Trengo.extraOffsetX, 'margin-bottom': Trengo.extraOffsetY, 'height': 'calc(100% - 20px - 75px - 20px - '+(Trengo.extraOffsetY||'0px')+')'}">
                <vue-iframe :nonce="nonce" class="trengo-vue-iframe" css-file="panel" :class="{'visible' : isOpen == true}" :title="$root.widget.translations.general.heading">
                    <panel @closeWidget="closeWidget" :helpCenterArticle="helpCenter" :flowbot="flowbot" :greeting="greeting" :color="$root.widget.color" :widget_settings="$root.widget"></panel>
                </vue-iframe>
            </div>
        </transition>
    </div>
</template>
