<script type="text/babel">

    import visitorToken from '../Util/VisitorToken';
    import chatState from '../Util/ChatState';

    export default {

        data()
        {
            return {
                name: '',
                email: '',
                message: '',
                custom_data: {},
                sending: false,
                sent: false,
                team_id: null,
                team_text: null,
                nameTextBoxShadow: null,
                messageTextBoxShadow: null,
                errors: []
            }
        },

        props: [
            'greeting',
        ],

        mounted()
        {
        },

        methods: {
            getGreetingMessageLanguage() {
                const lang = (navigator.language || navigator.userLanguage);

                let message = this.greeting.message.find(v => lang.startsWith(v.language));

                if(!message) {
                    message = this.greeting.message.find(v => v.default);
                }

                return message.language || ''
            },
            submit() {
                if (this.$parent.widget_settings.preview) {
                    return;
                }

                if (this.validate()) {

                    this.sending = true;

                    this.$http.post('ticket', {
                        name: this.name,
                        custom_field_data: this.custom_data,
                        vt: visitorToken.getVisitorToken(),
                        locale: this.$parent.widget_settings.locale,
                        contact_data: window.Trengo.contact_data,
                        ticket_data: window.Trengo.ticket_data,
                        team_id: this.team_id,
                        team_text: this.team_text,
                        new: true,
                        vd: chatState.getVisitorData(),
                        greeting: this.greeting.automationId ? {automationId: this.greeting.automationId, language: this.getGreetingMessageLanguage()} : null,
                        message: this.message
                    }).then(res => {
                        this.$emit('chatStarted', res.data);
                        Trengo.eventBus.$emit('widget.chat.started');
                        this.sending = false;
                    }).catch(() => {
                        alert('Something went wrong. Please try again.');
                        this.sending = false;
                    });
                }
            },
            validate() {
                this.errors = [];
                if (this.name.trim() === '') {
                    this.errors.push('name');
                }
                if (this.message.trim() === '') {
                    this.errors.push('message');
                }
                if (this.$parent.widget_settings.channels.chat.pre_form_custom_fields && this.$parent.widget_settings.channels.chat.pre_form_custom_fields) {
                    this.$parent.widget_settings.channels.chat.pre_form_custom_fields.forEach(field => {
                        if (field.required) {
                            if (field.type === 'team_selector') {
                                if (!this.team_id) {
                                    this.errors.push('team_id');
                                }
                            } else {
                                if (field.field_id && (this.custom_data['id:'+field.field_id] || '').trim() === '') {
                                    this.errors.push(field.title);
                                }
                                if (!field.field_id && (this.custom_data[field.title] || '').trim() === '') {
                                    this.errors.push(field.title);
                                }
                            }
                        }
                    });
                }

                if (!this.errors.length) {
                    return true;
                }
            },

            setTeamId(e) {
                this.team_id = e.target.value;
                this.team_text = e.target.querySelector('option:checked').innerHTML;
            },

            handleHeight(e) {
                e.target.style.height = 'auto';
                e.target.style.height = Math.min(e.target.scrollHeight) + "px";
            },

            changeInputStyle(style, name) {
                switch (name) {
                    case "name":
                        if (style === 'focus') {
                            this.nameTextBoxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                        } else {
                            this.nameTextBoxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                        }
                        break;
                    case "message":
                        if (style === 'focus') {
                            this.messageTextBoxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                        } else {
                            this.messageTextBoxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                        }
                }
            },

            changeInputStyleById(style, id) {
                let input = this.$refs[id][0]

                if (style === 'focus') {
                    input.style.boxShadow = "0px 3px 10px " + this.$parent.hexToRGB(this.$parent.widget_settings.color);
                } else {
                    input.style.boxShadow = "0px 3px 10px rgba(0, 0, 0, 0.1)";
                }
            }
        }
    }
</script>

<template>
    <form @submit.prevent="submit()" autocomplete="off" class="pt-2">
        <p style="font-size: 15px; line-height: 22px;">{{ $parent.widget_settings.translations.chat.pre_form_intro }}</p>
        <div class="floating-label relative">
            <input id="NameInput" @blur="changeInputStyle('default', 'name')" @focus="changeInputStyle('focus', 'name')" :style="{color: errors.includes('name') ? '#e3342f!important' : '#000000!important', boxShadow: errors.includes('name') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : nameTextBoxShadow}" :class="{'is-danger': errors.includes('name') }" name="name" v-model="name" class="form-control" type="text" :placeholder="$parent.widget_settings.translations.chat.name" >
            <label for="NameInput">{{ $parent.widget_settings.translations.chat.name }}:</label>
            <i class="material-icons absolute  text-red" style="right: 0; bottom: 14px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('name')">error_outline</i>
            <span class="sr-only" v-if="errors.includes('name')">{{ $parent.invalidErrorMessage }} "{{ $parent.widget_settings.translations.chat.name }}"</span>
        </div>
        <div class="form-group" v-for="(field, i) in $parent.widget_settings.channels.chat.pre_form_custom_fields">
            <div class="floating-label" v-if="typeof(field.type) === 'undefined' || field.type === 'text_field'">
                <input :ref="'Input'+i" :id="'Input'+i" @blur="changeInputStyleById('default', 'Input'+i)" @focus="changeInputStyleById('focus', 'Input'+i)" :style="{color: errors.includes(field.title) ? '#e3342f!important' : '#000000!important', boxShadow: errors.includes(field.title) ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : 'box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px'}"  :class="{'is-danger': errors.includes(field.title) }" :placeholder="field.title" :name="field.title" v-model="custom_data[field.field_id ? 'id:'+field.field_id : field.title]" class="form-control" type="text">
                <label :for="'Input'+i">{{ field.title }}:</label>
                <i class="material-icons absolute text-base text-red" style="right: 0; bottom: 14px; margin-right: 12px; font-size: 24px;" v-if="errors.includes(field.title)">error_outline</i>
                <span class="sr-only" v-if="errors.includes(field.title)">{{ $parent.invalidErrorMessage }} "{{field.title}}"</span>
            </div>
            <div v-if="field.type === 'team_selector'" class="">
                <div :ref="'Input'+i" :style="{boxShadow: errors.includes('team_id') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : 'box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px'}"  class="select">
                    <select :style="{color: errors.includes('team_id') ? '#e3342f!important' : '#000000!important'}" @blur="changeInputStyleById('default', 'Input'+i)" @focus="changeInputStyleById('focus', 'Input'+i)" class="form-control"  @change="setTeamId($event)" :class="{'is-danger': errors.includes('team_id') }" name="team_id">
                        <option :value="null">- {{ field.title }} -</option>
                        <option v-for="option in field.options" :value="option.value" >{{ option.label }}</option>
                    </select>
                    <i class="material-icons absolute text-base text-red" style="right: 0; bottom: 14px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('team_id')">error_outline</i>
                    <span class="sr-only" v-if="errors.includes('team_id')">{{ $parent.invalidErrorMessage }} "{{field.title}}"</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div :style="{color: errors.includes('team_id') ? '#e3342f!important' : '#9E9E9E!important', boxShadow: errors.includes('name') ? '0px 3px 10px rgba(213, 37, 37, 0.2)' : messageTextBoxShadow}" class="floating-label-textarea relative email">
                <textarea id="InputText" @blur="changeInputStyle('default', 'message')" :style="{color: errors.includes('message') ? '#e3342f!important' : '#000000!important'}" @input="handleHeight($event)" @focus="changeInputStyle('focus', 'message')" style="overflow: hidden; height: auto; box-shadow: none; outline: none" ref="textarea" :class="{'is-danger': errors.includes('message') }" name="message" :placeholder="$parent.widget_settings.translations.chat.message" v-model="message" class="form-control"></textarea>
                <i class="material-icons absolute text-base text-red" style="right: 0; top: 16px; margin-right: 12px; font-size: 24px;" v-if="errors.includes('message')">error_outline</i>
                <span class="sr-only" v-if="errors.includes('message')">{{ $parent.invalidErrorMessage }} "{{ $parent.widget_settings.translations.chat.message }}"</span>
                <div class="submit-email-container">
                    <button type="submit" class="button button-success send-email" :class="{ 'button-loading' : sending }" :style="{background: $parent.widget_settings.color }">
                        <div class="submit-email-content">
                            <i class="material-icons email-icon">send</i>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </form>
</template>
