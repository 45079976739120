
export default {
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
    // ucs-2 string to base64 encoded ascii
    utoa(str) {
        return window.btoa(unescape(encodeURIComponent(str)));
    },
    // base64 encoded ascii to ucs-2 string
    atou(str) {
        try {
            return decodeURIComponent(escape(window.atob(str)));
        } catch(e) {
            //console.log('something went wrong');
            return '';
        }
    },

    string(data, key) {
        let output = '';
        for(let i = 0; i < data.length; i++) {
            output += String.fromCharCode(data.charCodeAt(i) ^ key.charCodeAt(i % key.length));
        }
        return output;
    },

    encode(string, key) {
        if(!string || !key) {
            return;
        }
        return this.utoa(this.string(string, key));
    },

    decode(string, key) {
        if(!string || !key) {
            return;
        }
        return this.string(this.atou(string), key);
    }
}